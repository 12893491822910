import React, { Component } from "react";
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { Table, Row } from 'react-native-table-component';
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { submitForm, getForm, beginSubmit, checkRecordStatus, showStatusError, checkLogStatus, showLogStatusError, approved } from "../actions/menu";
import { sendSubmit, setBigFileIsLoading, loadingIssue } from "../actions/submissions";
import { setStatusChange, unsetStatusChange, getMyInventoryTypes, getMyItems, getMyPackaging } from "../actions/records";
import { setIsLogGroup, getGroupDetail, setLogGroupsSubAction } from "../actions/loggroups";
import { quitOrderForm } from "../actions/tasks";
import moment from "moment-timezone";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FormComponent from "./component";
import uuid from "uuid";
import LogDialog from "./dialogs/log";
import Section from "./components/section";
import RecordActions from "./widgets/recordActions";
import colors from "../constants/colors";
import alerts from "./components/alert"
import StorageService from "../services/storage";
import PaymentInfo from './dialogs/paymentInfo';
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";
import NewIssue from "./dialogs/newIssue";
import { editDefaultData } from "../actions/menu";
import { getLocations } from "../actions/submissions";
import { getIngredientLotsByName } from "../actions/submissions";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      lastStatus: false,
      checkboxkey: 0, // increment by 1 each time yesnoall is clicked
      components: [],
      choicelist: {},
      uuid: "",
      derived: false,
      autosave: false,
      tmpIssues: [],
      showPaymentInfo: false,
      callOnSave: false,
      lotConsecutive: 1,
      formLoaded: false,
      width: window.innerWidth,
      rerenderTable: false,
      openTableIssue: false,
      compIssueId: null,
      issueLabel: '',
      issuePosition: null,
      issueWidth: "small",
      palletComponentChanged: false,
      itemComponentChanged: false,
      updatedWizard: false,
      disableButton: false,
      batchSizeToCalculate: null,
      resetInventorySearch: { 
        changed: false,
        position: 0
      },
      inventoryLocations: [],
      dropDownOpen: -1
    };
    this.timeSubmissions = {};
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    this.props.getForm(this.props.submissionSelected?._id, () => {
      this.checkLotSubmissions();
      this.setState({ formLoaded: true });
    });
    window.addEventListener('resize', this.handleResize);
    if (this.props.isPallet || this.props.isInvoice || this.props.isShipmentLog || this.props.isInventoryTransfer) this.props.getMyInventoryTypes({ ignoreFilters: true, page: 1 });
    if (this.props.isRecipe || this.props.isReceivingLog || this.props.isOrder || this.props.isShipmentLog || this.props.isReconciliationLog || this.props.isInventoryTransfer) {
      var itemsData = { ignoreFilters: true, page: 1 };
      if(this.props.isShipmentLog || this.props.isInventoryTransfer)itemsData.fromShipmentLog = true;
      if(this.props.isRecipe)itemsData.forRecipe = true;
      this.props.getMyItems(itemsData);
      this.props.getMyPackaging({ ignoreFilters: true, page: 1 })
    }
    if(this.props.isInventoryTransfer || this.props.isRecipe){
      this.props.getLocations({
        page: 1, 
        ignoreFilters: true,
        company_id: this.props.company_id,
      }, (res)=>{
        if(res?.locations?.length){
          this.setState({ inventoryLocations: res.locations, itemComponentChanged: !this.state.itemComponentChanged});
        }
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  checkLotSubmissions() {
    const { submissionSelected } = this.props;
    let num_array = [];
    if (submissionSelected) {
      let sub_id = submissionSelected._id.slice(-8);
      for (let component of submissionSelected.components) {
        let obj = submissionSelected.submission[component.id];
        if (obj) {
          obj = obj.toString();
          if (component.type === "lotcode" || component.type === "customlotcode") {
            if (obj.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
          if (component.type === "inventory") {
            if (obj.lotcode && obj.lotcode.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
        }
      }
      var max = Math.max(...num_array);
      var min = Math.min(...num_array);
      if (max && max > 0) {
        this.setState({ lotConsecutive: max + 1 })
      }
    }
  }

  setCompleteStatus() {
    const { submission } = this.state;
    if (submission.status) {
      this.setState({ lastStatus: submission.status })
    }
    submission.status = "complete";
    this.setState({ submission: submission }, () => {
      this.submitForm("complete");
    });
  }

  saveForm = () => {
    this.setState(
      {
        autosave: true,
      },
      () => {
        this.props.autoSave(
          this.state.components,
          this.state.choicelist,
          this.state.submission,
          this.props.newReport ? uuid.v1() : this.state.uuid,
          this.props.task_id,
          this.state.autosave
        );
      }
    );
  };

  getComponentById(id) {
    const { components } = this.state;
    var r = null;
    for (var i = 0; i < components.length; i++) {
      if (components[i]["id"] == id) return components[i];
    }
  }

  getPalletSelected(value) {
    const { inventoryTypes } = this.props;
    var palletVal = null;
    inventoryTypes.map(pallet => {
      if (pallet.lot == value) palletVal = pallet;
    })

    return palletVal;
  }
  getItemSelected(value, type) {
    const { items, packagingMaterials } = this.props;
    var itemVal = null;
    switch (type) {
      case "ingredient":
        items.map(item => {
          if (item.itemName == value) itemVal = item;
        })
        break;
      case "packaging":
        packagingMaterials.map(item => {
          if (item.namePackaging == value) itemVal = item;
        })
        break;
    }
    return itemVal;
  }

  onComponentChange(id, value, subtype = false, position, prepopulate) {
    const { submission, components, lotConsecutive, palletComponentChanged, itemComponentChanged, resetInventorySearch, inventoryLocations } = this.state;
    const { isPallet, isReceivingLog, isOrder, isRecipe, isInvoice, isShipmentLog, defaultYield, isReconciliationLog, companyTimezone, isInventoryTransfer } = this.props;
    let newSubmission = JSON.parse(JSON.stringify(this.state.submission));
    let checkboxkey = this.state.checkboxkey;
    var c = this.getComponentById(id);
    if (typeof c != "undefined") {
      if (subtype) {
        if (typeof newSubmission[c.id] != "undefined") {
          // newSubmission[c.id][subtype] = value;
          if (newSubmission[c.id] == "") {
            newSubmission[c.id] = [];
          }
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id] = {};
            newSubmission[c.id][subtype] = value;
          }
        } else {
          newSubmission[c.id] = {};
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id][subtype] = value;
          }
          // newSubmission[c.id][subtype] = value;
        }
        if (subtype === "lotcode") {
          this.setState({ lotConsecutive: lotConsecutive + 1 })
        }
      } else {
        if (position || position == 0) {
          newSubmission[c.id][position] = value;
        } else {
          newSubmission[c.id] = value;
        }
      }
      if(c.type === "dropdown") {
        this.setState({ dropDownOpen: -1, rerenderTable: !this.state.rerenderTable });
      }
      //Receiving Log Form logic
      if ((isReceivingLog || isOrder || isReconciliationLog || isRecipe) && c?.defaultLabel == "ingredient"){
        var itemSelected = this.getItemSelected(value, "ingredient");
        if (itemSelected) {
          components.map(component => {
            if (component.defaultLabel != "ingredient" && component.defaultLabel != "quantity" && c.section_id == component.section_id) {
                var val = itemSelected[component.defaultLabel] ? itemSelected[component.defaultLabel] : "";
                if(component.defaultLabel == "price"){
                  val = itemSelected["costUnit"] ? itemSelected["costUnit"] : "";
                }
                if(typeof val == "object" && val.length >= 0){
                  var arr = val;
                  val = "";
                  arr.map((v, idx)=>{
                    if(idx == arr.length - 1){
                      val += v;
                    }else{
                      val += v + ", ";
                    }
                  })
                }
                if (position || position == 0) {
                  if (newSubmission[component.id]) newSubmission[component.id][position] = val;
                } else {
                  if (newSubmission[component.id]) newSubmission[component.id][0] = val;
                }
            }
            
            if(isReceivingLog && (component.defaultLabel == "quantity" || component.defaultLabel == "lotcode") ||
               isRecipe && (component.defaultLabel == "quantity" )){
              if (position || position == 0) {
                if (newSubmission[component.id]) newSubmission[component.id][position] = "";
              } else {
                if (newSubmission[component.id]) newSubmission[component.id][0] = "";
              }
            }
          })
        }
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      if ((isReceivingLog || isOrder || isReconciliationLog || isRecipe) && (c?.defaultLabel == "packagingMaterial" || c?.defaultLabel == "name")) {
        var itemSelected = this.getItemSelected(value, "packaging")
        if (itemSelected) {
          components.map(component => {
            if (component.defaultLabel != "packagingMaterial" && component.defaultLabel != "quantity" && c.section_id == component.section_id) {
              if(component.defaultLabel == "name"){
                if (position || position == 0) {
                  if (newSubmission[component.id]) newSubmission[component.id][position] = itemSelected.namePackaging ? itemSelected.namePackaging : "";
                } else {
                  if (newSubmission[component.id]) newSubmission[component.id][0] = itemSelected.namePackaging ? itemSelected.namePackaging : "";
                }
              }else if(component.defaultLabel == "price"){
                if (position || position == 0) {
                  if (newSubmission[component.id]) newSubmission[component.id][position] = itemSelected.cost ? itemSelected.cost : "";
                } else {
                  if (newSubmission[component.id]) newSubmission[component.id][0] = itemSelected.cost ? itemSelected.cost : "";
                }
              }else{
                if (position || position == 0) {
                  if (newSubmission[component.id]) newSubmission[component.id][position] = itemSelected[component.defaultLabel] ? itemSelected[component.defaultLabel] : "";
                } else {
                  if (newSubmission[component.id]) newSubmission[component.id][0] = itemSelected[component.defaultLabel] ? itemSelected[component.defaultLabel] : "";
                }
              }
            }
            if(isReceivingLog && (component.defaultLabel == "quantity" || component.defaultLabel == "lotcode") ||
               isRecipe && (component.defaultLabel == "quantity" )){
              if (position || position == 0) {
                if (newSubmission[component.id]) newSubmission[component.id][position] = "";
              } else {
                if (newSubmission[component.id]) newSubmission[component.id][0] = "";
              }
            }
          })
        }
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      //Recipe Batch Size Logic
      if (isRecipe && c.defaultLabel == "yield") {
        //Change logic to make calculations until save, send, edit required or approve
        // this.setState({ batchSizeToCalculate: value, itemComponentChanged: !itemComponentChanged  });

        //Old logic for Batch Size Logic
        newSubmission = this.calculateBatchSize(value, defaultYield, newSubmission, components);
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      //Invoice form Size Logic
      if (isInvoice) {
        if(c.defaultLabel == "item"){
          var rate = 0;
          var valid = "";
          components.filter(section => (section.type == 'section' && !section.hide && section?.defaultLabel == "invoiceItems")).map((section, id) => {
            components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
              // if(c.defaultLabel == "item"){
                switch (component?.defaultLabel) {
                  case "item":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.item;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.item;
                    }
                    break;
                    case "productCode":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = "";
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = "";
                    }
                    break;
                  case "description":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.description;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.description;
                    }
                    break;
                  case "quantity":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.amount;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.amount;
                    }
                    break;
                  case "rate":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.itemRate;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.itemRate;
                    }
                    break;
                  case "amount":
                    var amount = parseFloat(value?.amount) * parseFloat(value?.itemRate);
                    if (!amount || amount == "NaN") {
                      amount = 0;
                    }
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = amount;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = amount;
                    }
                    break;
                  case "units":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.units;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.units;
                    }
                    break;
                  case "class":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?.class;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?.class;
                    }
                    break;
                  case "item_invoice_id":
                    if (position || position == 0) {
                      if (newSubmission[component.id]) newSubmission[component.id][position] = value?._id;
                    } else {
                      if (newSubmission[component.id]) newSubmission[component.id][0] = value?._id;
                    }
                    break;
                }
              // }else{
              //   var lotQuantities = this.getLotsQuantity(value, position, c, submission, "inventoryType");
              //   switch (component?.defaultLabel) {
              //     case "lotcode":
              //       if(lotQuantities){
              //         if(lotQuantities?.valid){
              //           valid = lotQuantities?.valid;
              //           return;
              //         }
              //       }
              //       if (position || position == 0) {
              //         if (newSubmission[component.id]) newSubmission[component.id][position] = value;
              //       } else {
              //         if (newSubmission[component.id]) newSubmission[component.id][0] = value;
              //       }
              //       break;
              //     case "quantity":
              //       var qty = lotQuantities ? lotQuantities.quantity : 0;
              //       if(qty % 1 > 0){
              //         qty = qty.toFixed(3);
              //       }
              //       if (position || position == 0) {
              //         if (newSubmission[component.id]) newSubmission[component.id][position] = qty;
              //       } else {
              //         if (newSubmission[component.id]) newSubmission[component.id][0] = qty;
              //       }
              //       break;
              //     case "rate":
              //       if (position || position == 0) {
              //         if (newSubmission[component.id]) rate = newSubmission[component.id][position] ? parseFloat(newSubmission[component.id][position]) : 0;
              //       } else {
              //         if (newSubmission[component.id]) rate = newSubmission[component.id] ? parseFloat(newSubmission[component.id]) : 0;
              //       }
              //       break;
              //     case "amount":
              //       var amount = lotQuantities ? parseFloat(lotQuantities?.quantity): 0;
              //       var result = 0;
              //       if (!amount || amount == "NaN") {
              //         amount = 0;
              //       }
              //       result = rate > 0 && amount > 0 ? (rate * amount) : rate;
              //       if(result % 1 > 0){
              //         result = result.toFixed(3);
              //       }
              //       if (position || position == 0) {
              //         if (newSubmission[component.id]) newSubmission[component.id][position] = result;
              //       } else {
              //         if (newSubmission[component.id]) newSubmission[component.id][0] = result;
              //       }
              //       break;
              //   }
              // }
            });
          });

          if(valid){
            switch(valid){
              case "all_stock":
                this.alertConfirm("It looks like all the stock for this item was already selected", "Accept");
                break;
              case "lot_selected":
                this.alertConfirm("Sorry, It looks like this lot was already selected", "Accept");
                break;
            }
            this.setState({ submission: newSubmission, itemComponentChanged: { changed: !resetInventorySearch.changed, position: position }});
            return;
          }
        }
        

        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }

      //Shipment Log
      if(isShipmentLog){
        if(c.defaultLabel == "item" || c.defaultLabel == "lotcode_finished" || c.defaultLabel == "lotcode_wip"){
          var rate = 0;
          var valid = "";
          components.filter(section => (section.section_id == c.section_id && section.type == 'section' && !section.hide && (section?.defaultLabel == "finishedGoods" || section?.defaultLabel == "workInProgress"))).map((section, id) => {
            components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
              if(!valid){
                if(c.defaultLabel == "item"){
                  var ItemQuantities = this.getInventoryTypeQuantity(value, newSubmission, c, position, components, "inventoryType");
                  switch (component?.defaultLabel) {
                    case "item":
                      //if(ItemQuantities){
                      //  if(ItemQuantities?.valid){
                      //    valid = ItemQuantities?.valid;
                      //    if (position || position == 0) {
                      //      if (newSubmission[component.id]) newSubmission[component.id][position] = "";
                      //    } else {
                      //      if (newSubmission[component.id]) newSubmission[component.id][0] = "";
                      //    }
                      //    return;
                      //  }
                      //}
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.item;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.item;
                      }
                      break;
                    case "lotcode_finished":
                    case "lotcode_wip":
                      if (position || position == 0) {
                        // if (newSubmission[component.id]) newSubmission[component.id][position] = value?.lot;
                        var lotSelected = newSubmission[component.id][position];
                        if(typeof lotSelected == "object"){
                          lotSelected = value?.lotSelected;
                        }else{
                          lotSelected = [value?.lotSelected];
                        }
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.lotSelected;
                      } else {
                        // if (newSubmission[component.id]) newSubmission[component.id][0] = value?.lot;
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.lotSelected;
                      }
                      this.getItemLots(component?.object_type_id, value?.items);
                    break;
                    case "description":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.description;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.description;
                      }
                      break;
                    case "quantityFinishedGoods":
                    case "quantityWIP":
                      var qty = ItemQuantities ? ItemQuantities.quantity : value?.amount;
                      if(qty % 1 > 0){
                        qty = qty.toFixed(3);
                      }
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = qty;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = qty;
                      }
                      break;
                    case "units":
                    case "unitsWIP":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.units ? value?.units : "";
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.units ? value?.units : "";
                      }
                      break;
                    case "rate":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.itemRate;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.itemRate;
                      }
                      break;
                    case "amount":
                      var amount = ItemQuantities && value?.itemRate > 0 ? parseFloat(ItemQuantities?.quantity) * parseFloat(value?.itemRate) : value?.itemRate;
                      if (!amount || amount == "NaN") {
                        amount = 0;
                      } else if(amount % 1 > 0){
                        amount = amount.toFixed(3);
                      }
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = amount;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = amount;
                      }
                      break;
                    case "tax":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = "";
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = "";
                      }
                      break;
                    case "class":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?.class;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?.class;
                      }
                      break;
                    case "item_invoice_id":
                    case "item_invoice_id_wip":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value?._id;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value?._id;
                      }
                      break;
                  }
                }else{
                  var lotQuantities = this.getLotsQuantity(value, position, c, submission, "inventoryType");
                  switch (component?.defaultLabel) {
                    case "lotcode_finished":
                    case "lotcode_wip":
                      if(lotQuantities){
                        if(lotQuantities?.valid){
                          valid = lotQuantities?.valid;
                          return;
                        }
                      }
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = value;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = value;
                      }
                      break;
                    case "quantityFinishedGoods":
                    case "quantityWIP":
                      var qty = lotQuantities ? lotQuantities.quantity : 0;
                      if(qty % 1 > 0){
                        qty = qty.toFixed(3);
                      }
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = qty;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = qty;
                      }
                      break;
                    case "rate":
                      if (position || position == 0) {
                        if (newSubmission[component.id]) rate = newSubmission[component.id][position] ? parseFloat(newSubmission[component.id][position]) : 0;
                      } else {
                        if (newSubmission[component.id]) rate = newSubmission[component.id] ? parseFloat(newSubmission[component.id]) : 0;
                      }
                      break;
                    case "amount":
                      var amount = lotQuantities ? parseFloat(lotQuantities?.quantity): 0;
                      var result = 0;
                      if (!amount || amount == "NaN") {
                        amount = 0;
                      }
                      result = rate > 0 && amount > 0 ? (rate * amount) : rate;
                      if(result % 1 > 0){
                        result = result.toFixed(3);
                      }
                      if (position || position == 0) {
                        if (newSubmission[component.id]) newSubmission[component.id][position] = result;
                      } else {
                        if (newSubmission[component.id]) newSubmission[component.id][0] = result;
                      }
                      break;
                  }
                }
              }
            });
          });

          if(valid){
            switch(valid){
              case "all_stock":
                this.alertConfirm("No inventory available for this item", "Ok");
                break;
              case "lot_selected":
                this.alertConfirm("No inventory available for this item", "Ok");
                break;
            }
            this.setState({ submission: newSubmission, itemComponentChanged: { changed: !resetInventorySearch.changed, position: position }});
            return;
          }
        }
        valid = "";
        if(c?.defaultLabel == "ingredient"){
          var itemSelected = this.getItemSelected(value?.itemName, "ingredient");
          var ItemQuantities = this.getInventoryTypeQuantity(value, newSubmission, c, position, components, "ingredient");
          if(ItemQuantities){
            //valid = ItemQuantities?.valid ? ItemQuantities.valid : "";
            //if(valid){
              if (position || position == 0) {
                if (newSubmission[c.id]) newSubmission[c.id][position] = "";
              } else {
                if (newSubmission[c.id]) newSubmission[c.id][0] = "";
              }
              // return;
            //}
          }
          if (itemSelected && !valid) {
            components.map(component => {
              if (c.section_id == component.section_id) {
                if(component.defaultLabel == "lotcode"){
                  if (position || position == 0) {
                    var lotSelected = newSubmission[component.id][position];
                    if(typeof lotSelected == "object"){
                      lotSelected = value?.lotSelected;
                    }else{
                      lotSelected = [value?.lotSelected];
                    }
                    if (newSubmission[component.id]) newSubmission[component.id][position] = value?.lotSelected;
                  } else {
                    if (newSubmission[component.id]) newSubmission[component.id][0] = value?.lotSelected;
                  }
                  this.getItemLots(component?.object_type_id, value?.items);
                }else if(component.defaultLabel == "ingredient"){
                  if (position || position == 0) {
                    if (newSubmission[component.id]) newSubmission[component.id][position] = value?.itemName;
                  } else {
                    if (newSubmission[component.id]) newSubmission[component.id][0] = value?.itemName;
                  }
                }else if(component.defaultLabel == "quantityIngredient"){
                  var qty = ItemQuantities ? ItemQuantities.quantity : value?.amount;
                  if(qty % 1 > 0){
                    qty = qty.toFixed(3);
                  }
                  if (position || position == 0) {
                    if (newSubmission[component.id]) newSubmission[component.id][position] = qty;
                  } else {
                    if (newSubmission[component.id]) newSubmission[component.id][0] = qty;
                  }
                }else if(component.defaultLabel == "itemDescription"){
                  if (position || position == 0) {
                    if (newSubmission[component.id]) newSubmission[component.id][position] = itemSelected?.description ? itemSelected?.description : "";
                  } else {
                    if (newSubmission[component.id]) newSubmission[component.id][0] = itemSelected?.description ? itemSelected?.description : "";
                  }
                }else{ 
                  if (position || position == 0) {
                    if (newSubmission[component.id]) newSubmission[component.id][position] = component.defaultLabel == "ingredient_id" ? itemSelected?._id : itemSelected[component.defaultLabel];
                  } else {
                    if (newSubmission[component.id]) newSubmission[component.id][0] = component.defaultLabel == "ingredient_id" ? itemSelected?._id : itemSelected[component.defaultLabel];
                  }
                }
              }
            })
          }
        }else if(c?.defaultLabel == "lotcode"){
          components.map(component => {
            if (component.defaultLabel == "quantityIngredient" && c.section_id == component.section_id) {
              var lotQuantities = this.getLotsQuantity(value, position, c, submission,"ingredient");
              var qty = lotQuantities ? lotQuantities.quantity : 0;
              if(qty % 1 > 0){
                qty = qty.toFixed(3);
              }
              if(lotQuantities){
                if(lotQuantities?.valid){
                  valid = lotQuantities?.valid;
                  return;
                }
              }
              if (position || position == 0) {
                if (newSubmission[component.id]) newSubmission[component.id][position] = qty;
              } else {
                if (newSubmission[component.id]) newSubmission[component.id][0] = qty;
              }
            }
          })
        }
        if(valid){
          switch(valid){
            case "all_stock":
              this.alertConfirm("No inventory available for this item", "Ok");
              break;
            case "lot_selected":
              this.alertConfirm("No inventory available for this item", "Ok");
              break;
          }
          this.setState({ submission: newSubmission, itemComponentChanged: { changed: !resetInventorySearch.changed, position: position }});
          return;
        }
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      //Shipment Log Quantity edit
      if(isShipmentLog){
        var section = components.find(sect=> sect.section_id == c.section_id && sect.type == "section" && (sect?.defaultLabel == "finishedGoods" || sect?.defaultLabel == "workInProgress"));
        var amountComp = components.find(comp => comp.section_id == c.section_id && comp.type != "section" && comp?.defaultLabel == "amount");
        if(section && amountComp){
          if(c.defaultLabel == "quantityFinishedGoods" || (c.defaultLabel == "rate" && section?.defaultLabel == "finishedGoods")){
            var qtyComp = components.find(comp => comp.section_id == c.section_id && comp.type != "section" && comp?.defaultLabel == "quantityFinishedGoods");
            var rateComp = components.find(comp => comp.section_id == c.section_id && comp.type != "section" && comp?.defaultLabel == "rate");
            if(qtyComp && rateComp){
              var qty = (position || position == 0) ? parseFloat(newSubmission[qtyComp.id][position]) : parseFloat(newSubmission[qtyComp.id]);
              var rate = (position || position == 0) ? parseFloat(newSubmission[rateComp.id][position]) : parseFloat(newSubmission[rateComp.id]);
              var amount = qty * rate;
              if(amount > 0){
                if(position || position == 0){
                  newSubmission[amountComp.id][position] = amount.toString();
                }else{
                  newSubmission[amountComp.id] = amount.toString();
                }
              }
            }
          }
          
          if(c.defaultLabel == "quantityWIP" || (c.defaultLabel == "rate" && section?.defaultLabel == "workInProgress")){
              var qtyComp = components.find(comp => comp.section_id == c.section_id && comp.type != "section" && comp?.defaultLabel == "quantityWIP");
              var rateComp = components.find(comp => comp.section_id == c.section_id && comp.type != "section" && comp?.defaultLabel == "rate");
              if(qtyComp && rateComp){
                var qty = (position || position == 0) ? parseFloat(newSubmission[qtyComp.id][position]) : parseFloat(newSubmission[qtyComp.id]);
                var rate = (position || position == 0) ? parseFloat(newSubmission[rateComp.id][position]) : parseFloat(newSubmission[rateComp.id]);
                var amount = qty * rate;
                if(amount > 0){
                  if(position || position == 0){
                    newSubmission[amountComp.id][position] = amount.toString();
                  }else{
                    newSubmission[amountComp.id] = amount.toString();
                  }
                }
              }
          }
        }
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      //Production Date Recipe
      if((c.defaultLabel == "production_date") && isRecipe){
        var shelfLifeComponent = components.find(component => component.defaultLabel == "shelfLife");
        if(shelfLifeComponent){
          var days = 0;
          var daysToMultiply = 1;
          var repeat_interval = shelfLifeComponent?.prefilledData?.repeat_interval ? parseFloat(shelfLifeComponent?.prefilledData?.repeat_interval) : "";
          var repeat_frequency = shelfLifeComponent?.prefilledData?.repeat_frequency ? shelfLifeComponent?.prefilledData?.repeat_frequency : "";
          if(repeat_frequency){
            switch(repeat_frequency){
              case "Weeks":
                daysToMultiply = 7;
                break;
              case "Months":
                daysToMultiply = 30;
                break;
            }
          }
          if(repeat_interval && repeat_interval != "NaN"){
            days = daysToMultiply * repeat_interval;
          }
          if(days > 0){
            var productionComponent = components.find(component => component.defaultLabel == "production_date");
            var expirationComponent = components.find(component => component.defaultLabel == "expiration_date");
            if(productionComponent && expirationComponent){
              var productionDate = newSubmission[productionComponent.id] ? moment(newSubmission[productionComponent.id]).tz(companyTimezone) : "";
              var expirationDate = newSubmission[expirationComponent.id] ? moment(newSubmission[expirationComponent.id]).tz(companyTimezone) : ""; 
 
              if(productionDate && c.defaultLabel == "production_date"){
                expirationDate = productionDate.add(days, 'd');
                expirationDate = expirationDate.format("YYYY-MM-DDTHH:mm");
                newSubmission[expirationComponent.id] = expirationDate;
              }
              this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
            }
          }
        }
      }

      // YesNoAll logic
      if (c.type === "yesnoall") {
        var section = components[id].section_id;
        components.forEach((c) => {
          if (c.type === "yesno" && c.section_id === section) {
            if (position || position == 0) {
              newSubmission[c.id][position] = value;
            } else {
              newSubmission[c.id] = value;
            }
          }
        });
        // force yesno components to remount
        checkboxkey = checkboxkey + 1;
      }
      if (c.type === "lotcode" || c.type === "customlotcode") {
        // alert(lotConsecutive)
        this.setState({ lotConsecutive: lotConsecutive + 1 })
      }
      if(isInventoryTransfer){
        if(c?.defaultLabel == "item" || c?.defaultLabel == "itemName"){
          components.map(component => {
            if (component.defaultLabel != "itemName" && component.defaultLabel != "item" && c.section_id == component.section_id) {
              var clearValue = "";
              if((component.defaultLabel == "fromLocation"  || component.defaultLabel == "toLocation")){
                clearValue = [''];
              }else if(component.defaultLabel == "lotcode"){
                //Preselect the oldest lot code
                var section = components.find((section)=> section.type == "section" && section.section_id == c.section_id);
                var type = "";
                if(section?.defaultLabel == "rawMaterials"){
                  type = "ingredient";
                }
                if(section?.defaultLabel == "finishedGoods"){
                  type = "fg";
                }
                if(section?.defaultLabel == "packagingMaterials"){
                  type = "packagingMaterial";
                }
                if(section?.defaultLabel == "workInProgress"){
                  type = "wip";
                }
                clearValue = ["default_lot"];
                if(type){
                  this.getIngredientLotsByName(value, type, (lots) => {
                    if(lots?.length > 0){
                      clearValue = [lots[0]];
                    }
                    var qtyComponent = components.find((component)=> c.section_id == component.section_id && component.type != "section" && component.defaultLabel == "quantity");
                    var qty = 0;

                    if(lots[0]?.historial?.length > 0){
                      var location = lots[0]?.historial[0]?.location;
                      var fromLocationComponent = components.find((component)=> c.section_id == component.section_id && component.type != "section" && component.defaultLabel == "fromLocation");
                      var fromLocationValue = [];
                    
                      if(fromLocationComponent){
                        fromLocationValue = this.filterLocations(location, inventoryLocations);
                        if(position || position == 0) {
                          newSubmission[fromLocationComponent.id][position] = fromLocationValue;
                        } else {
                          newSubmission[fromLocationComponent.id] = fromLocationValue;
                        }

                        lots[0].historial.map((h)=>{
                          var location = h?.location ? h.location : null;
                          if(location){
                            var existLocation = this.findLocation(location, fromLocationValue);
                           if(existLocation){
                             qty += h.amount;
                           }
                          }
                        });
                      }
                    }
                    if (!qty || qty == "NaN") {
                      qty = 0;
                    } else if(qty % 1 > 0){
                      qty = qty.toFixed(3);
                    }
                    if (position || position == 0) {
                      if (newSubmission[component.id])newSubmission[component.id][position] = clearValue;
                      if (qtyComponent && newSubmission[qtyComponent?.id])newSubmission[qtyComponent.id][position] = qty;
                    } else {
                      if(newSubmission[component.id])newSubmission[component.id][0] = clearValue;
                      if(qtyComponent && newSubmission[qtyComponent?.id])newSubmission[qtyComponent.id] = qty;
                    }

                    this.setState({ submission: newSubmission, itemComponentChanged: !this.state.itemComponentChanged });
                  })
                }
              } 
              if (position || position == 0) {
                if (newSubmission[component.id]) newSubmission[component.id][position] = clearValue;
              } else {
                if (newSubmission[component.id]) newSubmission[component.id][0] = clearValue;
              }
            }
          });
        }else if(c?.defaultLabel == "lotcode"){
          var qtyComponent = components.find((component)=> c.section_id == component.section_id && component.type != "section" && component.defaultLabel == "quantity");
          var qty = 0;

          if(value[0]?.historial?.length > 0){
            var location = value[0]?.historial[0]?.location;
            var fromLocationComponent = components.find((component)=> c.section_id == component.section_id && component.type != "section" && component.defaultLabel == "fromLocation");
            var fromLocationValue = [];

            if(fromLocationComponent){
              fromLocationValue = this.filterLocations(location, inventoryLocations);
              
              value[0].historial.map((h)=>{
                var location = h?.location ? h.location : null;
                if(location){
                  var existLocation = this.findLocation(location, fromLocationValue);
                  if(existLocation){
                    qty += h.amount;
                  }
                }
              });
              if (!qty || qty == "NaN") {
                qty = 0;
              } else if(qty % 1 > 0){
                qty = qty.toFixed(3);
              }
              if(position || position == 0) {
                newSubmission[fromLocationComponent.id][position] = fromLocationValue;
                if (qtyComponent && newSubmission[qtyComponent?.id])newSubmission[qtyComponent.id][position] = qty;
              } else {
                newSubmission[fromLocationComponent.id] = fromLocationValue;
                if(qtyComponent && newSubmission[qtyComponent?.id])newSubmission[qtyComponent.id] = qty;
              }
              if(position || position == 0) {
                newSubmission[qtyComponent.id][position] = qty;
              } else {
                newSubmission[qtyComponent.id] = qty;
              }
            }
          }
        }
        this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged });
      }
      if(prepopulate && isRecipe){
        if(value[0]?.historial?.length > 0){
          var location = value[0]?.historial[0]?.location;
          var locationComponent = components.find((component)=> c.section_id == component.section_id && component.type != "section" && component.defaultLabel == "location");
          var filteredInventoryLocations = [];

          if(locationComponent){
            if(value[0]?.historial){
              value[0].historial.map((h)=>{
                if(h?.location){
                  if(h?.location){
                    var location = h?.location;
                    var fromLocationValue = [];
                    this.state.inventoryLocations.map((l) => {
                      var found = true;
                      if(location == "default_location"){
                        if(!l?.site?.defaultLocation){
                          found = false;
                        }else{
                          fromLocationValue.push(l);
                          return found;
                        }
                      }
                      if(location?.site?._id){
                        if(l?.site?._id != location?.site?._id){
                          found = false;
                        }else{
                          if(l?.zone?._id && !location?.zone?._id)found = false;
                        }
                      }
                      if(location?.zone?._id){
                        if(l?.zone?._id != location?.zone?._id){
                          found = false;
                        }else{
                          if(l?.aisle?._id && !location?.aisle?._id)found = false;
                        }
                      }
                      if(location?.aisle?._id){
                        if(l?.aisle?._id != location?.aisle?._id){
                          found = false;
                        }else{
                          if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                        }
                      }
                      if(location?.rackBay?._id){
                        if(l?.rackBay?._id != location?.rackBay?._id){
                          found = false;
                        }else{
                          if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                        }
                      }
                      if(location?.shelfLevel?._id){
                        if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                          found = false
                        }else{
                          if(l?.bin?._id && !location?.bin?._id)found = false;
                        }
                      }
                      if(location?.bin?._id){
                        if(l?.bin?._id != location?.bin?._id){
                          found = false
                        }
                      }
                      if(found){
                        fromLocationValue.push(location);
                      }
    
                      return found;
                    });
                    fromLocationValue.map((locationValue)=>{
                      var foundLocation = filteredInventoryLocations.find((l)=>{
                        var found = true;
                        if(locationValue?.site?.defaultLocation){
                          if(!l?.site?.defaultLocation){
                            found = false;
                          }
                        }
                        if(location?.site?._id){
                          if(l?.site?._id != location?.site?._id){
                            found = false;
                          }else{
                            if(l?.zone?._id && !location?.zone?._id)found = false;
                          }
                        }
                        if(location?.zone?._id){
                          if(l?.zone?._id != location?.zone?._id){
                            found = false;
                          }else{
                            if(l?.aisle?._id && !location?.aisle?._id)found = false;
                          }
                        }
                        if(location?.aisle?._id){
                          if(l?.aisle?._id != location?.aisle?._id){
                            found = false;
                          }else{
                            if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                          }
                        }
                        if(location?.rackBay?._id){
                          if(l?.rackBay?._id != location?.rackBay?._id){
                            found = false;
                          }else{
                            if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                          }
                        }
                        if(location?.shelfLevel?._id){
                          if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                            found = false
                          }else{
                            if(l?.bin?._id && !location?.bin?._id)found = false;
                          }
                        }
                        if(location?.bin?._id){
                          if(l?.bin?._id != location?.bin?._id){
                            found = false
                          }
                        }
      
                        return found;
                      });
  
                      if(!foundLocation){
                        filteredInventoryLocations.push(locationValue);
                      }
                    });
                  }
                }
              })
            }
            if(position || position == 0) {
              newSubmission[locationComponent.id][position] = filteredInventoryLocations[0] ? [filteredInventoryLocations[0]] : [];
            } else {
              newSubmission[locationComponent.id] = filteredInventoryLocations[0] ? [filteredInventoryLocations[0]] : [];
            }
            this.setState({ submission: newSubmission, itemComponentChanged: !this.state.itemComponentChanged });
          }
        }
      }
    } else {
      if (subtype) {
        newSubmission[id] = {};
        if (position || position == 0) {
          newSubmission[id][position][subtype] = value;
        } else {
          newSubmission[id][position] = value;
        }
      } else {
        if (position || position == 0) {
          newSubmission[id][position] = value;
        } else {
          newSubmission[id] = value;
        }
      }
      // newSubmission[id] = value;
    }
    this.setState({ submission: newSubmission, checkboxkey: checkboxkey, itemComponentChanged: !this.state.itemComponentChanged });
  }
  getInventoryTypeQuantity(item, submission, c, position, components, type){
    const { inventoryTypes } = this.props;

    var quantity = null;
    var res = null;
    var valid = "";
    var items = submission[c.id];
    const rawMaterials = this.props.items;
    if(item){
      if(item?.items){
        item.items.map((lotCode)=>{
          if(lotCode == item?.lotSelected){
            if(lotCode?.historial){
              lotCode.historial.map((historial)=>{
                if(historial?.amount > 0){
                  quantity += historial?.amount;
                }
              });
            }
          }else if(item?.lotSelected == "default_lot" || item?.lotSelected == ["default_lot"]){
            if(lotCode?.historial){
              lotCode.historial.map((historial)=>{
                if(historial?.amount > 0){
                  quantity += historial?.amount;
                }
              });
            }
          }
        });
        res = {
          quantity,
          valid
        };
      }
    }
    if(items){
      items.map((itm, idx)=>{
        if(item?.item == itm && position != idx){
          var itemsSubmissionId = null;
          var inventoryType = type == "inventoryType" ? inventoryTypes.find((inv, idx)=> inv?.item == item?.item) : rawMaterials.find((inv, idx)=> inv?.item == item?.item);

          components.filter(section => (section.section_id == c.section_id && section.type == 'section' && !section.hide && (section?.defaultLabel == "finishedGoods" || section?.defaultLabel == "workInProgress" || section?.label == "Raw Materials"))).map((section, id) => {
            components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
              switch(component?.defaultLabel){
                case "lotcode_finished":
                case "lotcode_wip":
                case "lotcode":
                    if(submission[component.id][idx] == "default_lot" || submission[component.id][idx] == ["default_lot"]){
                      valid = "all_stock";          
                    }else{
                      var lots = [];
                      if(inventoryType){
                          if((inventoryTypes && type == "inventoryType") || (rawMaterials && type == "ingredient")){
                            lots = submission[component.id][idx];
                            if(lots?.length > 0 && typeof lots == "object"){
                              lots.map((lot)=>{
                                var foundLot = inventoryType?.items?.find((itm)=> itm?.lot == lot?.lot);
                                if(foundLot){
                                  foundLot?.historial.map((h)=>{
                                      if(h?.amount > 0){
                                        quantity = quantity - h.amount;
                                      }
                                  })
                                }
                              })
                            }
                          }
                      }
                    }
                break;
              }
            });
          });
        }
      });
      if(quantity <= 0){
        valid = "all_stock";
      }
      res = {
        quantity,
        valid
      };
    }
    return res;
  }
  getLotsQuantity(lots, position, component, submission, type){
    const { inventoryTypes, items } = this.props;
    const { components } = this.state;

    var quantity = null;
    var res = null;
    var lotsSelecteds = submission[component.id];
    var valid = "";
    if(lots?.length > 0 && typeof lots == "object"){
        if(lots[0] == "default_lot"){
          var itemComponent =  components.find(c => (c.defaultLabel == "item" || c.defaultLabel == "ingredient") && c.section_id == component.section_id);
          if(itemComponent){
            var item = submission[itemComponent.id] ? submission[itemComponent.id][position] : "";
            var allLots = type == "inventoryType" ? inventoryTypes.find((inv)=> inv?.item == item) : items.find((inv)=> inv?.itemName == item) ;
            if(allLots){
              if(allLots?.items){
                allLots.items.map((lotCode)=>{
                  if(lotCode?.historial){
                    lotCode.historial.map((historial)=>{
                      if(historial?.amount > 0){
                        quantity += historial?.amount;
                      }
                    });
                  }
                })
              }
            }
          }
        }else{
          lots.map((lotCode)=>{
              if(lotsSelecteds){
                lotsSelecteds.map((lotselected, idx)=>{
                  if(idx != position && lotselected){
                    lotselected.map((lS)=>{
                      if(lS?.lot == lotCode?.lot){
                        valid = "lot_selected";
                      }
                    })
                  }
                })
              }
              if(lotCode?.historial){
                lotCode.historial.map((historial)=>{
                  if(historial?.amount > 0){
                    quantity += historial?.amount;
                  }
                });
              }
          });
        }
        
        res = {
          valid,
          quantity,
        };
    }
    return res;
  }
  getItemLots(object_type_id, items){
    const { choicelist, components } = this.state;
    var newChoicelist = JSON.parse(JSON.stringify(choicelist));
    newChoicelist[object_type_id] = items;

    this.setState({ choicelist: newChoicelist });
  }

  calculateBatchSize(v, d, submission, components) {
    var newSubmission = submission;

    var yield_section = components.filter((c) => c.defaultLabel == "moreRecipeInformation");
    var ingredients_section = components.filter((c) => c.defaultLabel == "ingredients");
    var packaging_materials_section = components.filter((c) => c.defaultLabel == "packagingMaterials");
    var value = parseFloat(v);
    var defaultYield = d ? parseFloat(d) : 1;
    value = value;
    defaultYield = defaultYield ? defaultYield : 1;
    
    //Check for yield section
    if(yield_section.length > 0){
      var yield_id = components.filter(c => c.section_id == yield_section[0].section_id && c?.defaultLabel == "amount");
      if(yield_id.length > 0){
        newSubmission[yield_id[0].id][0] = v;
      }
    }
    //Check for ingredients
    if (ingredients_section.length > 0) {
      var ingredients_id = components.filter(c => c.section_id == ingredients_section[0].section_id && c.label == "Quantity");
      if (ingredients_id.length > 0) {
        var id = ingredients_id[0].id;
        var qty_sub = submission[id];
        qty_sub.map((q, idx) => {
          var val = parseFloat(q);
          val = val;
          if (val && value) {
            var defaultSubmission = ingredients_section[0]?.defaultSubmission ? ingredients_section[0]?.defaultSubmission[id] : null;
            if (defaultSubmission) {
              defaultSubmission = parseFloat(defaultSubmission[idx]);
              if (defaultSubmission) {
                var new_value = (defaultSubmission / defaultYield) * value;
                newSubmission[id][idx] = new_value.toFixed(2);
              }
            } else {
              var new_value = (val / defaultYield) * value;
              newSubmission[id][idx] = new_value.toFixed(2);
            }
          }
        });
      }
    }

    //Check for packaging materials
    if (packaging_materials_section.length > 0) {
      var packaging_id = components.filter(c => c.section_id == packaging_materials_section[0].section_id && c.label == "Quantity");
      if (packaging_id.length > 0) {
        var id = packaging_id[0].id;
        var qty_sub = submission[id];
        qty_sub.map((q, idx) => {
          var val = parseFloat(q);
          val = val;
          if (val && value) {
            var defaultSubmission = packaging_materials_section[0]?.defaultSubmission ? packaging_materials_section[0]?.defaultSubmission[id] : null;
            if (defaultSubmission) {
              defaultSubmission = parseFloat(defaultSubmission[idx]);
              if (defaultSubmission) {
                var new_value = (defaultSubmission / defaultYield) * value;
                newSubmission[id][idx] = new_value.toFixed(2);
              }
            } else {
              var new_value = (val / defaultYield) * value;
              newSubmission[id][idx] = new_value.toFixed(2);
            }
          }
        });
      }
    }
    return newSubmission;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { companyTimezone, isRecipe, newReport } = this.props;
    var submission = {};
    var components = [];
    var choicelist = {};
    var uuid = "";
    var issues = newProps.submissionSelected?.issues
    if (issues) {
      issues = Object.values(issues)
      if (issues.length >= 0) {
        this.setState({ tmpIssues: issues }, this.forceUpdate.bind(this));
      }
    }
    // Initialize right after the form is loaded from network/memory
    if (!newProps.isLoaded || !this.props.isLoading) {
      this.setState({
        components: newProps.components,
        updatedWizard: !this.state.updatedWizard

      });
      return;
    }

    // Load old submission
    if (!this.props.newReport && !this.props.isSubmission) {
      uuid = this.props.previousSubmissions[this.props.task_id]["uuid"];
      submission = this.props.previousSubmissions[this.props.task_id][
        "submission"
      ];
      components = this.props.previousSubmissions[this.props.task_id][
        "components"
      ];
      choicelist = this.props.previousSubmissions[this.props.task_id][
        "choicelist"
      ];
    }

    if (!this.props.newReport && this.props.isSubmission) {
      uuid = this.props.submissionSelected.uuid;
      submission = this.props.submissionSelected.submission;
      const tmpComponents = this.props.submissionSelected.components;
      choicelist = this.props.submissionSelected.choicelist;
      let indextmpComponents = 0
      tmpComponents.forEach((comp, indx) => {
        let nComponent = { ...comp }
        if (comp.type === "section") {

          newProps.components.forEach(nComp => {
            if (nComp.label === comp.label) {
              if (nComp.attachmentId && typeof nComp.attachmentId !== 'undefined' && typeof nComp.attachmentId !== '' && typeof nComp.attachmentId !== null) {
                nComponent.attachment = nComp.attachment;
                nComponent.attachment_type = nComp.attachment_type
                nComponent.attachmentId = nComp.attachmentId

              }
            }

          });
        }
        components.push(nComponent)
      })
    }

    if ("components" in newProps && "choicelist" in newProps) {
      const date = moment().format("YYYY-MM-DD HH:mm");
      const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
      // New report
      if (this.props.newReport) {
        components = newProps.components;
        choicelist = newProps.choicelist;
        // components.filter(section => section.type == 'section').forEach((c) => {

        // })
        components.forEach((c) => {
          var tableSect = components.filter(sect => c.section_id == sect.section_id && sect.type == "section" && sect?.direction == "table" && c.type != "section");

          if (tableSect.length > 0) {
            var defaultData = 1;
            var dataType = tableSect[0]?.dataType ? tableSect[0]?.dataType : null;
            if (tableSect[0]?.defaultData > 0) {
              defaultData = tableSect[0]?.defaultData;
            } else {
              defaultData = 1;
            }
            defaultData = defaultData - 1;
            var defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
            for (var comp = 0; comp <= defaultData; comp++) {
              if (comp == 0) {
                if (c.type === "datetime") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [initialDateTime];
                    }
                  } else {
                    submission[c.id] = [initialDateTime];
                  }
                } else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ) {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [""];
                    }
                  } else {
                    submission[c.id] = [""];
                  }
                } else if (c.type === "dropdown") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                    }
                  } else {
                    submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                  }
                } else if (c.type === "signature"
                  || c.type === "timer"
                  || c.type === "timerauto") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                } else if (c.type == "yesno" || c.type == "yesnoall") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "document") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [0];
                    }
                  } else {
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "chatAI") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [{ response: "", question: "" }];
                    }
                  } else {
                    submission[c.id] = [{ response: "", question: "" }];
                  }
                }
                else if (c.type == "inventory") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    } else if (c.prefilledData) {
                      submission[c.id] = [c.prefilledData];
                    } else {
                      submission[c.id] = [{ lotcode: "", productname: "", quantity: "" }];
                    }
                  } else {
                    submission[c.id] = [{ lotcode: "", productname: "", quantity: "" }];
                  }
                }
              } else {
                var sub = submission[c.id];
                if (c.type === "datetime") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      // submission[c.id] = [defaultSubmission[c.id][comp]];
                      sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      sub.push(c.prefilledData);
                    } else {
                      sub.push(initialDateTime);
                    }
                  } else {
                    sub.push(initialDateTime);

                  }
                }
                else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ) {

                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push("");
                    }
                  } else {
                    submission[c.id] = sub.push("");
                  }
                } else if (c.type === "dropdown") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                    }
                  } else {
                    submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                  }
                }
                else if (c.type === "signature"
                  || c.type === "timer"
                  || c.type === "timerauto") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "yesno" || c.type == "yesnoall") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                } else if (c.type == "document") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push(0);
                    }
                  } else {
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "chatAI") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push({ response: "", question: "" });
                    }
                  } else {
                    submission[c.id] = sub.push({ response: "", question: "" });
                  }
                }
                else if (c.type == "inventory") {
                  if (defaultSubmission[c.id]) {
                    if (defaultSubmission[c.id][comp]) {
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    } else if (c.prefilledData) {
                      submission[c.id] = sub.push(c.prefilledData);
                    } else {
                      submission[c.id] = sub.push({ lotcode: "", productname: "", quantity: "" });
                    }
                  } else {
                    submission[c.id] = sub.push({ lotcode: "", productname: "", quantity: "" });
                  }
                }

                submission[c.id] = sub;
              }
            }
          } else {
            if (c.type === "datetime") submission[c.id] = initialDateTime;
            else if (
              c.type === "inputlabel" ||
              c.type === "input" ||
              c.type === "textarea" ||
              c.type === "lotecode" ||
              c.type === "customlotcode" ||
              c.type === "picture"
            ) {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = "";
              }
            } else if (c.type === "dropdown") {

              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = choicelist[c.object_type_id][0]["id"];
              }
            }
            else if (c.type === "signature"
              || c.type === "timer"
              || c.type === "timerauto") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            } else if (c.type == "yesno" || c.type == "yesnoall") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            } else if (c.type == "document") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = 0;
              }
            }
            else if (c.type == "chatAI") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = { response: "", question: "" };
              }
            }
            else if (c.type == "inventory") {
              if (c.prefilledData) {
                submission[c.id] = c.prefilledData;
              } else {
                submission[c.id] = { lotcode: "", productname: "", quantity: "" };
              }
            }
          }
        });
      }
    }
    var productionComponent = components.find(component => component.defaultLabel == "production_date");
    if(productionComponent && components && submission && isRecipe && newReport){
      var shelfLifeComponent = components.find(component => component.defaultLabel == "shelfLife");
        if(shelfLifeComponent){
          var days = 0;
          var daysToMultiply = 1;
          var repeat_interval = shelfLifeComponent?.prefilledData?.repeat_interval ? parseFloat(shelfLifeComponent?.prefilledData?.repeat_interval) : "";
          var repeat_frequency = shelfLifeComponent?.prefilledData?.repeat_frequency ? shelfLifeComponent?.prefilledData?.repeat_frequency : "";
          if(repeat_frequency){
            switch(repeat_frequency){
              case "Weeks":
                daysToMultiply = 7;
                break;
              case "Months":
                daysToMultiply = 30;
                break;
            }
          }
          if(repeat_interval && repeat_interval != "NaN"){
            days = daysToMultiply * repeat_interval;
          }
          if(days > 0){
            var expirationComponent = components.find(component => component.defaultLabel == "expiration_date");
            if(productionComponent && expirationComponent){
              var productionDate = submission[productionComponent.id] ?  moment(submission[productionComponent.id]).tz(companyTimezone) : "";
              var expirationDate = submission[expirationComponent.id] ?  moment(submission[expirationComponent.id]).tz(companyTimezone) : ""; 
              if(productionDate){
                expirationDate = productionDate.add(days, 'd');
                expirationDate = expirationDate.format("YYYY-MM-DDTHH:mm");
                submission[expirationComponent.id] = expirationDate;
              }
            }
          }
        }
    }
    this.setState({
      submission,
      components,
      choicelist,
      uuid,
      rerenderTable: !this.state.rerenderTable
    });
  }

  // Do not render when this.state.submissions is modified
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isErrorSave !== nextProps.isErrorSave ||
      this.props.isErrorRetrieve !== nextProps.isErrorRetrieve ||
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.isSaving !== nextProps.isSaving ||
      this.props.updatedWizard !== nextProps.updatedWizard ||
      this.props.submissionSelected !== nextProps.submissionSelected ||
      this.state.updatedWizard !== nextState.updatedWizard ||
      this.state.lotConsecutive !== nextState.lotConsecutive ||
      this.state.checkboxkey !== nextState.checkboxkey ||
      this.state.rerenderTable != nextState.rerenderTable ||
      this.state.openTableIssue !== nextState.openTableIssue ||
      this.props.inventoryTypes !== nextProps.inventoryTypes ||
      this.props.items !== nextProps.items ||
      this.state.palletComponentChanged !== nextState.palletComponentChanged ||
      this.state.itemComponentChanged !== nextState.itemComponentChanged || 
      this.state.resetInventorySearch !== nextState.resetInventorySearch ||
      this.state.choicelist !== nextState.choicelist ||
      this.state.inventoryLocations !== nextState.inventoryLocations ||
      this.state.dropDownOpen !== nextState.dropDownOpen
    );
  }

  submitFormSave(status = "in_progress", sendAnyway) {
    this.props.startSubmit(
      this.state.components,
      this.state.choicelist,
      this.state.submission,
      this.props.newReport ? uuid.v1() : this.state.uuid,
      this.props.task_id ? this.props.task_id : this.props.submissionSelected?.task_id,
      this.props.isLogGroup ? this.props.logGroupSelected.id : '',
      status,
      this.props.isRecipe ? this.props.isRecipe : null,
      () =>{
        //Navigate te log group or mainview
        this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');
      },
      () =>{
        //Retry
        this.submitFormSave(status, true);
      },
      sendAnyway,
      this.props.isInventoryTransfer ? this.props.isInventoryTransfer : null,
    );
    //this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');
  }

  submitForm = async (status, sendAnyway) => {
    const { submission, lastStatus } = this.state;
    const { isOrder, formId, isInventoryTransfer } = this.props;
    let cont = 0;
    let labels = [];
    const result = this.state.components.filter(f => f.mandatory === true);

    for (let i = 0; i < result.length; i++) {
      let index = result[i]?.id;
      let valueMandatory = this.state.submission[index];
      if (valueMandatory != undefined) {
        if (valueMandatory.length > 0 || valueMandatory != 0) {

          if(typeof valueMandatory == "object" && valueMandatory?.length > 0){
            var addToCount = true;
            valueMandatory.map((valMandatory)=>{
              if (result[i].type === 'inventory') {
                if (valMandatory.lotcode && valMandatory.productname && valMandatory.quantity) {
                  if (valMandatory.lotcode.length > 0 && valMandatory.productname.length > 0 && valMandatory.quantity.length > 0) {
                    // cont++;
                  } else {
                    addToCount = false;
                  }
                } else {
                  addToCount = false;
                }
    
              } else {
                if (valMandatory == undefined) {
                  addToCount = false;
                }else{
                  if (valMandatory.length <= 0) {
                    addToCount = false;
                  }
                }
              }
            });

            if(addToCount){
              cont++;
            }else{
              labels.push(result[i].label);
            }
            
          }else{
            if (result[i].type === 'inventory') {
              if (valueMandatory.lotcode && valueMandatory.productname && valueMandatory.quantity) {
                if (valueMandatory.lotcode.length > 0 && valueMandatory.productname.length > 0 && valueMandatory.quantity.length > 0) {
                  cont++;
                } else {
                  labels.push(result[i].label);
                }
              } else {
                labels.push(result[i].label);
              }
  
            } else {
              cont++;
            }
          }
        } else {
          labels.push(result[i].label);
        }
      } else {
        labels.push(result[i].label);
      }
    }
    if (cont === result.length) {
      if(isInventoryTransfer){
        if(!this.checkToLocationOnTransfer(this.state.components, this.state.submission)){
          await this.props.setStatusChange();
          return;
        }
      }
      await this.props.unsetStatusChange();
      this.props.startSubmit(
        this.state.components,
        this.state.choicelist,
        this.state.submission,
        this.props.newReport ? uuid.v1() : this.state.uuid,
        this.props.task_id ? this.props.task_id : this.props.submissionSelected?.task_id,
        this.props.isLogGroup ? this.props.logGroupSelected.id : '',
        status,
        this.props.isRecipe ? this.props.isRecipe : null,
        () => {
          if(status == "approved"){
            this.props.approved();
            this.props.navigation.navigate('RecordsView')
          }else{
            this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');
          }
        },
        () =>{
          //Retry
          this.submitForm(status, true);
        },
        sendAnyway,
        this.props.isInventoryTransfer ? this.props.isInventoryTransfer : null,
      );
      if (isOrder && status == "complete") {
        this.props.quitOrderForm(formId);
      }
      // this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');
    } else {
      await this.props.setStatusChange();
      submission.status = lastStatus;
      this.setState({ submission: submission })

      Swal.fire({
        title: "Please check your form",
        text: "Complete the required fields before submitting the report " + '(' + labels.toString().replaceAll(",", ", ") + ')',
        confirmButtonText: "Ok",
        confirmButtonColor: colors.orange,
        backdrop: false
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }


  navigateToGroup() {
    this.props.setLogGroupsSubAction(),
      this.props.navigation.navigate('GroupView');
  }

  renderIssue(issues, component) {
    let issueComment = "";
    let found = false;
    let issueRender = []
    if (Array.isArray(issues)) {
      issues?.map((issue) => {
        if (issue.id == component.id) {
          issueComment = issue.comment;
          found = true;
          issueRender = issue;
        }
      });
    }
    return found ? issueRender : null;

  }

  printAllIssues(issues, component, pos, fromTable) {
    const { companyTimezone, submissionSelected } = this.props;
    const status = submissionSelected ? submissionSelected.status : "new";
    const locIssues = issues.filter(issue => issue?.id == component?.id);
    var position = pos;
    if (fromTable && pos == null) {
      position = 0;
    }
    return (
      <View style={{ flex: 1 }}>

        {locIssues.map(issue => {
          if (position || position == 0) {
            if (position == issue?.position) {
              return <React.Fragment>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                    {issue.solved ?
                      <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                      <Text style={styles.issueText}>{issue?.comment}</Text>}
                  </TouchableOpacity>
                  {!issue.solved &&
                    <TouchableOpacity
                      disabled={status == "archived" || status == "approved"}
                      style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                      onPress={() => {
                        const { submissionSelected } = this.props;
                        const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                        const newIssues = [...issues];
                        newIssues[commentIndx] = {
                          ...issue,
                          solved: true
                        }
                        this.props.loadingIssue(component.id);
                        this.setState({ rerenderTable: !this.state.rerenderTable })
                        this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                          this.props.loadingIssue(null);
                          this.setState({ rerenderTable: !this.state.rerenderTable })
                        });
                      }}
                    >
                      <Text style={styles.issueText}>Solve</Text>
                    </TouchableOpacity>
                  }
                </View>
                {
                  issue.createdBy && issue.createdDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>

                    : null
                }
                {
                  issue.solvedBy && issue.solvedDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>

                    : null
                }
                <View>

                </View>
              </React.Fragment>
            }
            else if (position == 0 && fromTable && !issue?.position) {
              return <React.Fragment>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                    {issue.solved ?
                      <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                      <Text style={styles.issueText}>{issue?.comment}</Text>}
                  </TouchableOpacity>
                  {!issue.solved &&
                    <TouchableOpacity
                      disabled={status == "archived" || status == "approved"}
                      style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                      onPress={() => {
                        const { submissionSelected } = this.props;
                        const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                        const newIssues = [...issues];
                        newIssues[commentIndx] = {
                          ...issue,
                          solved: true
                        }
                        this.props.loadingIssue(component.id);
                        this.setState({ rerenderTable: !this.state.rerenderTable })
                        this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                          this.props.loadingIssue(null);
                          this.setState({ rerenderTable: !this.state.rerenderTable })
                        });
                      }}
                    >
                      <Text style={styles.issueText}>Solve</Text>
                    </TouchableOpacity>
                  }
                </View>
                {
                  issue.createdBy && issue.createdDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>

                    : null
                }
                {
                  issue.solvedBy && issue.solvedDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>

                    : null
                }
                <View>

                </View>
              </React.Fragment>
            }
            return null;
          } else {
            return <React.Fragment>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                  {issue.solved ?
                    <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                    <Text style={styles.issueText}>{issue?.comment}</Text>}
                </TouchableOpacity>
                {!issue.solved &&
                  <TouchableOpacity
                    disabled={status == "archived" || status == "approved"}
                    style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                    onPress={() => {
                      const { submissionSelected } = this.props;
                      const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                      const newIssues = [...issues];
                      newIssues[commentIndx] = {
                        ...issue,
                        solved: true
                      }
                      this.props.loadingIssue(component.id);
                      this.setState({ rerenderTable: !this.state.rerenderTable })
                      this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                        this.props.loadingIssue(null)
                        this.setState({ rerenderTable: !this.state.rerenderTable })
                      });
                    }}
                  >
                    <Text style={styles.issueText}>Solve</Text>
                  </TouchableOpacity>
                }
              </View>
              {
                issue.createdBy && issue.createdDate ?
                  <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>

                  : null
              }
              {
                issue.solvedBy && issue.solvedDate ?
                  <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>

                  : null
              }
              <View>

              </View>
            </React.Fragment>
          }

        }
        )
        }

      </View>
    )
  }

  callCalculateBatchSize(callback){
    const { defaultYield } = this.props;
    const { batchSizeToCalculate, submission, components, itemComponentChanged } = this.state;

    let newSubmission = JSON.parse(JSON.stringify(submission));
    newSubmission = this.calculateBatchSize(batchSizeToCalculate, defaultYield, newSubmission, components);
    this.setState({ submission: newSubmission, itemComponentChanged: !itemComponentChanged },()=>{
      if(callback)callback();
    });
  }

  batchSizeAlert(callback) {
    Swal.fire({
      text: "Are you sure you want to increase/decrease the ingredients and yield of this recipe?.",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: colors.orange,
      backdrop: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.callCalculateBatchSize(callback);
      } else {
        this.setState({ batchSizeToCalculate: null },()=>{
          if(callback)callback();
        });
      }
    });
  }

  makeSave(){
    const { submissionSelected, taskSelected, isLogGroup } = this.props;
    const { formLoaded } = this.state;
    const status = submissionSelected ? submissionSelected.status : "new";  

    //Validate Status
    var id = taskSelected ? taskSelected?._id : null;
    if (status == "new" && isLogGroup) {
      this.props.checkLogStatus(id, (statusBD) => {
        if (!statusBD || statusBD == "pending" || statusBD == "edit_required") {
          if (formLoaded && this.state.disableButton == false){   
            this.setState({ disableButton: true });
            setTimeout(()=>{this.submitFormSave('in_progress');
            });
          }
        } else {
          this.props.showLogStatusError(statusBD);
          this.props.navigation.navigate('MainView')
        }
      })
    
    } else {
      if (!id) {
        id = this.props.newReport ? uuid.v1() : this.state.uuid;
      }
      this.props.checkRecordStatus(id, (statusBD) => {
        if (!statusBD || statusBD == "in_progress" || statusBD == "pending" || statusBD == "edit_required") {
          if (formLoaded && this.state.disableButton == false) {
            this.setState({ disableButton: true });
            this.submitFormSave('in_progress');
          }
        } else {
          this.props.showStatusError(statusBD);
          this.props.navigation.navigate('MainView')
        }
      })
    }
  }

  checkBatchForSubmitForm(status){
    const { isRecipe } = this.props;
    const { batchSizeToCalculate } = this.state;
    if(batchSizeToCalculate != null && isRecipe){
      this.batchSizeAlert(()=>{
        if(status)this.submitForm(status);else this.submitForm();
      });
    }else{
      if(status)this.submitForm(status);else this.submitForm();
    }
  }

  checkBatchForSubmitFormSave(status){
    const { isRecipe } = this.props;
    const { batchSizeToCalculate } = this.state;
    if(batchSizeToCalculate != null && isRecipe){
      this.batchSizeAlert(()=>{
        if(status)this.submitFormSave(status);else this.submitFormSave();
      });
    }else{
      if(status)this.submitFormSave(status);else this.submitFormSave();
    }
  }

  showSave() {

    const { submissionSelected, formId, taskSelected, isLogGroup, defaultYield, isRecipe } = this.props;
    const { formLoaded, batchSizeToCalculate, submission, components, itemComponentChanged } = this.state;
    const status = submissionSelected ? submissionSelected.status : "new";    
    if (status != 'archived' && status != 'approved' && status != 'complete')
      return (
        <View style={styles.submitContainer}>
          <TouchableOpacity
            style={styles.button}
            disabled={this.state.disableButton}
            onPress={() => {
              if (this.props.payment_completed) {
                // if(batchSizeToCalculate != null && isRecipe){
                //   this.batchSizeAlert(()=>{
                //     this.makeSave();
                //   });
                // }else{
                  this.updateTimeSubmissions(()=>{
                    this.makeSave();
                  });
                // }
              } else {
                this.setState({ showPaymentInfo: true, callOnSave: true }, () => { this.forceUpdate() });
              }
            }}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </View>
      );
    else if (status != 'complete')
      return (
        <TouchableOpacity style={styles.readOnlyContainer}>
          <Text style={{ alignSelf: "center" }}>This record is read only</Text>
        </TouchableOpacity>
      );


  }

  saveIssue(issue, issues, submissionId, callback) {
    const newIssues = Array.isArray(issues) ? [...issues, issue] : [issue];
    this.props.sendSubmit(submissionId, newIssues, issue.comment, callback);
  }

  convertUTCDateToLocalDate(date) {

    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-US');
  }
  getTableHeaders(components, section_id) {
    const { isInvoice, isShipmentLog } = this.props;
    var labels = [];
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      var label = component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? component?.label + " *" : component?.label;
      if (!isInvoice && !isShipmentLog){
        if(!component?.hide){
          labels.push(label);
        }
      }else{
        if (component?.defaultLabel != "item_invoice_id" && component?.defaultLabel != "ingredient_id" && component?.defaultLabel != "item_invoice_id_wip"){
          if(!component?.hide){
            labels.push(label);
          }
        }
      }
    });
    return labels;
  }
  getRowComponents(components, section_id, position, widthArr, backgroundColor, rows) {
    var filteredComponents = [];
    const { isLoadingIssue, submissionSelected, companyTimezone, formSubmission, isPallet, userRole, isRecipe, isInvoice, isShipmentLog, inventoryTypes, isReceivingLog, isInventoryTransfer, items, packagingMaterials, newReport } = this.props;
    const { choicelist, checkboxkey, lotConsecutive, submission, resetInventorySearch } = this.state;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    const status = submissionSelected ? submissionSelected.status : "new";
    let issues = submissionSelected ? submissionSelected.issues : null;
    var tableSect = components.filter(sect => section_id == sect.section_id && sect.type == "section" && sect?.direction == "table");
    var defaultSubmission = {};
    var defaultData = null;
    var wipPositions = [];
    var sectionDefaulLabel = "";
    var section_id = "";

    if (tableSect.length > 0) {
      defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
      wipPositions = tableSect[0]?.wipPositions ? tableSect[0]?.wipPositions : [];
      if (tableSect[0]?.defaultData) {
        defaultData = tableSect[0]?.defaultData - 1;
      } else {
        defaultData = 0;
      }
      if(tableSect[0]?.defaultLabel){
        sectionDefaulLabel = tableSect[0]?.defaultLabel;
      }
      if(tableSect[0]?.section_id){
        section_id = tableSect[0]?.section_id;
      }
    }
    var componentIdx = 0;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      var initial;
      var disabled = false;
      let new_label;
      var showComponent = true;
      var itemInvoice_id = null;
      var showWipSearch = "";
      var options = [];
      var inventoryLocations = this.state.inventoryLocations;
      switch (component.type) {
        case "customlotcode":
          new_label = `${component.label} - ${component.lotcodetypename}`;
          break;
        case "inventory":
          new_label = `${component.label} - ${component.inventorytypename} Code`;
          break;
        default:
          new_label = `${component.label}`;
      }
      if (component?.dataType) {
        switch (component?.dataType) {
          case "default_data":
            if (position <= defaultData) {
              disabled = true;
            }
            if (position >= defaultData) {
              if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "description" ||
                component?.defaultLabel == "quantity" ||
                component?.defaultLabel == "rate" ||
                component?.defaultLabel == "amount" ||
                component?.defaultLabel == "tax" ||
                component?.defaultLabel == "class")) {
                disabled = true;
              }
            }
            break;
          default:
            if (isPallet && component?.defaultLabel == "status") {
              if (userRole == "HACCP Coordinator" || userRole == "QA Manager" || userRole == "Person In Charge") {
                disabled = false;
              } else {
                disabled = true;
              }
            } else {
              disabled = false;
            }
            break;
        }
      } else {
        if (isPallet && component?.defaultLabel == "status") {
          if (userRole == "HACCP Coordinator" || userRole == "QA Manager" || userRole == "Person In Charge") {
            disabled = false;
          } else {
            disabled = true;
          }
        } else {
          disabled = false;
        }
      }
      if (position || position == 0) {
        if (submission[component.id]) {
          if (typeof submission[component.id] == "object" && submission[component.id].length > 0) {
            if (submission[component.id][position] !== undefined) {
              initial = submission[component.id][position]
            } else {
              initial = "";
            }
          } else {
            initial = submission[component.id]
          }
        } else {
          initial = "";
        }
      } else {
        initial = "";
      }
      if (isInvoice || isShipmentLog) {
        itemInvoice_id = components.filter(component => component.section_id == section_id && component.type != 'section').filter((c, idx) => c?.defaultLabel == "lotcode" || c?.defaultLabel == "item_invoice_id_wip");
        
        if (itemInvoice_id.length > 0) {
          itemInvoice_id = itemInvoice_id[0].id;
        }
      }
      if(component?.hide){
        showComponent = false;
      }
      if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "item_invoice_id" || component?.defaultLabel == "ingredient_id" || component?.defaultLabel == "item_invoice_id_wip")) {
        showComponent = false;
      }
      if ((isShipmentLog) && component?.defaultLabel == "ingredient_id") {
        showComponent = false;
      }
      if(isRecipe && component?.type == "customlotcode" && wipPositions?.length > 0){
        wipPositions.map((wipPos)=>{
          if(wipPos == position){
            var ingredientComponent = components.filter(component => component.section_id == section_id && component.type != 'section').filter((c, idx) => c?.defaultLabel == "ingredient");
            if(ingredientComponent?.length > 0){
              var ingredientComponentId = ingredientComponent[0].id;
              if(submission[ingredientComponentId]){
                if(submission[ingredientComponentId][position]){
                  showWipSearch = submission[ingredientComponentId][position];
                }
              }
            }
          }
        })
      }

      if (showComponent) {
        if((isInventoryTransfer && component?.defaultLabel == "fromLocation") || (isRecipe && component?.defaultLabel == "location")){
          var lotComponent = components.find(component => component.section_id == section_id && component.type != 'section' && component?.defaultLabel == "lotcode");
          if(lotComponent){
            var lotSelected = [];
            if(position || position == 0) {
              if(submission[lotComponent.id]){
                lotSelected = submission[lotComponent.id][position];
              }
            } else {
              lotSelected = submission[lotComponent.id];
            }
            if(lotSelected?.length > 0 && typeof lotSelected == "object"){
              var filteredInventoryLocations = [];
              lotSelected.map((lot)=>{
                var gotTransfers = false;
                if(lot != "default_lot"){
                  if(lot?.historial){
                    lot.historial.map((h)=>{
                      if(h?.location){
                        var location = h?.location;
                        if(location != "default_location")gotTransfers = true;
                        var fromLocationValue = [];
                        this.state.inventoryLocations.map((l) => {
                          var found = true;
                          if(location == "default_location"){
                            if(!l?.site?.defaultLocation){
                              found = false;
                            }else{
                              fromLocationValue.push(l);
                              return found;
                            }
                          }
                          if(location?.site?._id){
                            if(l?.site?._id != location?.site?._id){
                              found = false;
                            }else{
                              if(l?.zone?._id && !location?.zone?._id)found = false;
                            }
                          }
                          if(location?.zone?._id){
                            if(l?.zone?._id != location?.zone?._id){
                              found = false;
                            }else{
                              if(l?.aisle?._id && !location?.aisle?._id)found = false;
                            }
                          }
                          if(location?.aisle?._id){
                            if(l?.aisle?._id != location?.aisle?._id){
                              found = false;
                            }else{
                              if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                            }
                          }
                          if(location?.rackBay?._id){
                            if(l?.rackBay?._id != location?.rackBay?._id){
                              found = false;
                            }else{
                              if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                            }
                          }
                          if(location?.shelfLevel?._id){
                            if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                              found = false
                            }else{
                              if(l?.bin?._id && !location?.bin?._id)found = false;
                            }
                          }
                          if(location?.bin?._id){
                            if(l?.bin?._id != location?.bin?._id){
                              found = false
                            }
                          }
                          if(found){
                            fromLocationValue.push(location);
                          }
        
                          return found;
                        });
                        fromLocationValue.map((locationValue)=>{
                          var foundLocation = filteredInventoryLocations.find((l)=>{
                            var found = true;
                            if(locationValue?.site?.defaultLocation){
                              if(!l?.site?.defaultLocation){
                                found = false;
                              }
                            }
                            if(location?.site?._id){
                              if(l?.site?._id != location?.site?._id){
                                found = false;
                              }else{
                                if(l?.zone?._id && !location?.zone?._id)found = false;
                              }
                            }
                            if(location?.zone?._id){
                              if(l?.zone?._id != location?.zone?._id){
                                found = false;
                              }else{
                                if(l?.aisle?._id && !location?.aisle?._id)found = false;
                              }
                            }
                            if(location?.aisle?._id){
                              if(l?.aisle?._id != location?.aisle?._id){
                                found = false;
                              }else{
                                if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                              }
                            }
                            if(location?.rackBay?._id){
                              if(l?.rackBay?._id != location?.rackBay?._id){
                                found = false;
                              }else{
                                if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                              }
                            }
                            if(location?.shelfLevel?._id){
                              if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                                found = false
                              }else{
                                if(l?.bin?._id && !location?.bin?._id)found = false;
                              }
                            }
                            if(location?.bin?._id){
                              if(l?.bin?._id != location?.bin?._id){
                                found = false
                              }
                            }
          
                            return found;
                          });
    
                          if(!foundLocation){
                            filteredInventoryLocations.push(locationValue);
                            inventoryLocations = filteredInventoryLocations;
                          }
                        });
                      }
                    })
                    if(!gotTransfers){
                      var defaultLocation = this.state.inventoryLocations.filter((l) => {
                        var found = false;
                        if(l?.site?.defaultLocation){
                          found = true;
                        }
      
                        return found;
                      });
    
                      inventoryLocations = defaultLocation;
                    }
                  }
                }else{
                  var itemComponent = components.find(component => component.section_id == section_id && component.type != 'section' && (component?.defaultLabel == "itemName" || component?.defaultLabel == "item" || (isRecipe && component?.defaultLabel == "ingredient") || (isRecipe && component?.defaultLabel == "name")));
                  if(itemComponent){
                    var name = submission[itemComponent.id][position] ? submission[itemComponent.id][position] : "";
                    var item = null;
                    var lots = [];
                    if(sectionDefaulLabel == "rawMaterials"){
                      item = items.find((i)=> i?.itemName == name);
                    }else if(sectionDefaulLabel == "finishedGoods"){
                      item = inventoryTypes.find((i)=> i?.item == name);
                    }else if(sectionDefaulLabel == "packagingMaterials"){
                      item = packagingMaterials.find((i)=> i?.namePackaging == name);
                    }else if(sectionDefaulLabel == "workInProgress"){
                      item = inventoryTypes.find((i)=> i?.item == name);
                    }else if(isRecipe && sectionDefaulLabel == "ingredients"){
                      item = items.find((i)=> i?.itemName == name);
                    }

                    if(item){
                      if(item?.items){
                        lots = item.items;
                      }
                      if(lots?.length > 0){
                        lots.map((lot)=>{
                          if(lot?.historial?.length > 0){
                            lot.historial.map((h)=>{
                              if(h?.location){
                                var location = h?.location;
                                if(location != "default_location")gotTransfers = true;
                                var fromLocationValue = [];
                                this.state.inventoryLocations.map((l) => {
                                  var found = true;
                                  if(location == "default_location"){
                                    if(!l?.site?.defaultLocation){
                                      found = false;
                                    }else{
                                      fromLocationValue.push(l);
                                      return found;
                                    }
                                  }
                                  
                                  if(location?.site?._id){
                                    if(l?.site?._id != location?.site?._id){
                                      found = false;
                                    }else{
                                      if(l?.zone?._id && !location?.zone?._id)found = false;
                                    }
                                  }
                                  if(location?.zone?._id){
                                    if(l?.zone?._id != location?.zone?._id){
                                      found = false;
                                    }else{
                                      if(l?.aisle?._id && !location?.aisle?._id)found = false;
                                    }
                                  }
                                  if(location?.aisle?._id){
                                    if(l?.aisle?._id != location?.aisle?._id){
                                      found = false;
                                    }else{
                                      if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                                    }
                                  }
                                  if(location?.rackBay?._id){
                                    if(l?.rackBay?._id != location?.rackBay?._id){
                                      found = false;
                                    }else{
                                      if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                                    }
                                  }
                                  if(location?.shelfLevel?._id){
                                    if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                                      found = false
                                    }else{
                                      if(l?.bin?._id && !location?.bin?._id)found = false;
                                    }
                                  }
                                  if(location?.bin?._id){
                                    if(l?.bin?._id != location?.bin?._id){
                                      found = false
                                    }
                                  }
                
                                  if(found){
                                    fromLocationValue.push(location);
                                  }

                                  return found;
                                });
                                fromLocationValue.map((locationValue)=>{
                                  var foundLocation = filteredInventoryLocations.find((l)=>{
                                    var found = true;
                                    if(locationValue?.site?.defaultLocation){
                                      if(!l?.site?.defaultLocation){
                                        found = false;
                                      }
                                    }

                                    if(location?.site?._id){
                                      if(l?.site?._id != location?.site?._id){
                                        found = false;
                                      }else{
                                        if(l?.zone?._id && !location?.zone?._id)found = false;
                                      }
                                    }
                                    if(location?.zone?._id){
                                      if(l?.zone?._id != location?.zone?._id){
                                        found = false;
                                      }else{
                                        if(l?.aisle?._id && !location?.aisle?._id)found = false;
                                      }
                                    }
                                    if(location?.aisle?._id){
                                      if(l?.aisle?._id != location?.aisle?._id){
                                        found = false;
                                      }else{
                                        if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
                                      }
                                    }
                                    if(location?.rackBay?._id){
                                      if(l?.rackBay?._id != location?.rackBay?._id){
                                        found = false;
                                      }else{
                                        if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
                                      }
                                    }
                                    if(location?.shelfLevel?._id){
                                      if(l?.shelfLevel?._id != location?.shelfLevel?._id){
                                        found = false
                                      }else{
                                        if(l?.bin?._id && !location?.bin?._id)found = false;
                                      }
                                    }
                                    if(location?.bin?._id){
                                      if(l?.bin?._id != location?.bin?._id){
                                        found = false
                                      }
                                    }
                  
                                    return found;
                                  });
            
                                  if(!foundLocation){
                                    filteredInventoryLocations.push(locationValue);
                                    inventoryLocations = filteredInventoryLocations;
                                  }
                                });
                              }
                            })
                          }
                        });
                        if(!gotTransfers){
                          var defaultLocation = this.state.inventoryLocations.filter((l) => {
                            var found = false;
                            if(l?.site?.defaultLocation){
                              found = true;
                            }
          
                            return found;
                          });
        
                          inventoryLocations = defaultLocation;
                        }
                      }
                    }
                  }
                }
                
              })
            }
            var defaultLocationIdx = inventoryLocations.findIndex((loc)=> loc?.site?.defaultLocation == true);
            if(defaultLocationIdx > 0){
              var dL = inventoryLocations.splice(defaultLocationIdx, 1);
              if(dL){
                inventoryLocations.splice(0, 0, dL[0]);
              }
            }

            // if(inventoryLocations?.length > 0){
            //   inventoryLocations = this.sortLocations(inventoryLocations);
            // }
          }
        }
        filteredComponents.push(
          <View style={component.type == "dropdown" ? { zIndex: 1, padding: 3,width: widthArr[componentIdx], backgroundColor } : { padding: 3, width:  widthArr[componentIdx], backgroundColor}}>
            {
              isLoadingIssue != null && component.id == isLoadingIssue ?
                <ActivityIndicator size={150} color="rgb(0, 47, 108)"></ActivityIndicator>
                :
                <>
                  <FormComponent
                    id={component.id}
                    type={component.type}
                    key={component.id}
                    label={component.type == "inventory" ? new_label : ''}
                    placeholder={component.placeholder ? component.placeholder : ""}
                    componentLink={component.componentLink ? component.componentLink : ""}
                    enabledLink={component.enabledLink ? component.enabledLink : ""}
                    initial={initial}
                    onChange={(id, value, subtype, position, prepopulate) => {
                      if(component.type == "time" || component.type == "timer"){
                        this.onTimeComponentChange(id, value, position);
                      }else{
                        this.onComponentChange(id, value, subtype, position, prepopulate)
                      }
                    }}
                    options={choicelist[component.object_type_id]}
                    checkboxkey={checkboxkey}
                    status={submissionSelected ? submissionSelected.status : false}
                    submissionSelected={submissionSelected ? submissionSelected : null}
                    showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                    lotConsecutive={lotConsecutive}
                    customlot={false}
                    isIssuable={submissionId != null}
                    onSubmitIssue={(issue) => {
                      this.props.loadingIssue(component.id);
                      this.saveIssue({ ...issue, position: position }, issues, submissionId)
                      setTimeout(() => {
                        this.props.loadingIssue(null);
                      }, 1000)
                    }}
                    openIssue={() => {
                      this.setState({
                        openTableIssue: true,
                        compIssueId: component.id,
                        issueLabel: '',
                        issuePosition: position,
                        issueWidth: component?.width ? component?.width : "small",
                        rerenderTable: !this.state.rerenderTable
                      });
                    }}
                    companyTimezone={companyTimezone}
                    position={position}
                    width={component?.width ? component?.width : "small"}
                    defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : disabled}
                    closeIssue={() => {
                      this.setState({ rerenderTable: !this.state.rerenderTable });
                    }}
                    showInventorySearch={component?.showInventorySearch || (isRecipe && component?.defaultLabel == "lotcode" && (sectionDefaulLabel == "ingredients" || sectionDefaulLabel == "packagingMaterials" )) || (isInvoice && component?.defaultLabel == "lotcode")}
                    showFinishedGoodsSearch={component?.showFinishedGoodsSearch}
                    showWorkInProgressSearch={component?.showWorkInProgressSearch}
                    showItemsSearch={component?.showItemsSearch || (isRecipe && (component?.defaultLabel == "ingredient") && (sectionDefaulLabel == "ingredients"))}
                    showPackagingSearch={component?.showPackagingSearch || (isRecipe && (component?.defaultLabel == "name") && (sectionDefaulLabel == "packagingMaterials" ))}
                    defaultLabel={component?.defaultLabel}
                    isRecipe={isRecipe}
                    isInvoice={isInvoice}
                    submission={submission}
                    itemInvoice_id={itemInvoice_id}
                    isShipmentLog={isShipmentLog}
                    resetInventorySearch={resetInventorySearch}
                    showWipSearch={showWipSearch}
                    components={components}
                    inventoryTypes={inventoryTypes}
                    searchClients={component?.searchClients}
                    searchSuppliers={isReceivingLog && component?.defaultLabel == "approvedSupplier"}
                    section_id={section_id}
                    isInventoryTransfer={isInventoryTransfer}
                    inventoryLocations={inventoryLocations}
                    newReport={newReport}
                    openDropDown={(pos)=>{
                      this.openDropDown(pos);
                    }}
                    backgroundColor={backgroundColor}
                    isLast={position == rows}
                  />
                  {issues && this.renderIssue(issues, component) != null ? <View style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 10,

                  }}>
                    {this.printAllIssues(issues, component, position, true)}
                  </View> : null}
                </>
            }

          </View>
        )
        componentIdx++;
      }
    })

    return filteredComponents;
  }

  renderRows(rows, components, section_id) {
    const { dropDownOpen } = this.state;
    var renderedRows = [];
    var widthArr = this.getCellArrayWidth(components, section_id);
    for (let i = 0; i <= rows; i++) {
      //renderedRows.push(<Row data={this.getRowComponents(components, section_id, i)} widthArr={this.getCellArrayWidth(components, section_id)} style={i % 2 == 0 ? { backgroundColor: '#EDEEEE', justifyContent: "flex-start" } : { backgroundColor: 'white', justifyContent: "flex-start"}} textStyle={styles.text} />);
      renderedRows.push(<View style={{ backgroundColor: 'white', display: "flex", flexDirection: "row", zIndex: i == dropDownOpen ? 1 : 0}}>
          {this.getRowComponents(components, section_id, i, widthArr, i % 2 == 0 ? '#EDEEEE' : 'white', rows)}
      </View>);
    }

    return renderedRows;
  }

  getCellArrayWidth(components, section_id) {
    const { isInvoice, isShipmentLog } = this.props;
    var widthArray = [];
    var w = 150;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      var showComponent = true;
      if ((isInvoice || isShipmentLog) && (component?.defaultLabel == "item_invoice_id" || component?.defaultLabel == "ingredient_id" || component?.defaultLabel == "item_invoice_id_wip")) {
        showComponent = false;
      }
      if(component?.hide){
        showComponent = false;
      }

      if (showComponent) {
        switch (component?.width) {
          case 'mini':
            w = 134;
            break;
          case 'small':
            w = 200;
            break;
          case 'medium':
            w = 300;
            break;
          case 'large':
            w = 480;
            break;
          default:
            w = 200;
            break;
        }
        widthArray.push(w);
      }
    });
    return widthArray;
  }

  checkForRows(components, section_id) {
    const { submission } = this.state;
    var rowNumber = null;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      if(component?.defaultLabel != "item_invoice_id_wip"){
        var sub = submission[component.id];
        if (typeof sub === 'object' && sub?.length) {
          rowNumber = sub.length;
        }
      }
    })
    if (!rowNumber) {
      rowNumber = 1;
    }
    return rowNumber - 1;
  }
  restTableRow(section_id, firstRow) {
    const { companyTimezone } = this.props;
    const { submission, components, resetInventorySearch } = this.state;
    var newSubmission = JSON.parse(JSON.stringify(submission));
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");

    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      if (submission[component.id]) {
        if (typeof submission[component.id] == "object" && submission[component.id].length > 0) {
          if(firstRow){
            switch (component.type) {
              case "datetime":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSub[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = initialDateTime;
                  }
                }
                break;
              case "dropdown":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = "";
                  }
                }
                break;
              case
                "signature",
                "timer",
                "timerauto",
                "document":
                if (newSubmission[component.id][0]) {
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = 0;
                  }
                }
                break;
              case "yesno",
                "yesnoall":
                if (newSubmission[component.id][0]){
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = 0;
                  }
                }
                break;
              default:
                if (newSubmission[component.id][0]){
                  if (component.prefilledData) {
                    newSubmission[component.id][0] = component.prefilledData;
                  } else {
                    newSubmission[component.id][0] = "";
                  }
                }
              break;
            }
          }else{
            newSubmission[component.id].splice(submission[component.id].length - 1, 1);
          }
        }
      }
    });
    if(firstRow){
      this.setState({ submission: newSubmission, resetInventorySearch: { changed: !resetInventorySearch.changed, position: 0 }});
    }
    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable });

  }

  addTableRow(section_id, choicelist) {
    const { companyTimezone } = this.props;
    const { submission, components } = this.state;
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    var newSubmission = JSON.parse(JSON.stringify(submission));
    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      var sub = submission[component.id];
      if (typeof sub === 'object' && sub.length > 0) {
        switch (component.type) {
          case "datetime":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(initialDateTime);
            }
            break;
          case "dropdown":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(choicelist[component.object_type_id][0]["id"]);
            }
            break;
          case
            "signature",
            "timer",
            "timerauto",
            "document":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(0);
            }
            break;
          case "yesno",
            "yesnoall":
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push(0);
            }
            break;
          default:
            if (component.prefilledData) {
              newSubmission[component.id].push(component.prefilledData);
            } else {
              newSubmission[component.id].push("");
            }
            break;
        }
      } else {
        var newSub = [sub];
        switch (component.type) {
          case "datetime":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(initialDateTime);
            }
            break;
          case "dropdown":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(choicelist[component.object_type_id][0]["id"]);
            }
            break;
          case
            "signature",
            "timer",
            "timerauto",
            "document":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(0);
            }
            break;
          case "yesno",
            "yesnoall":
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push(0);
            }
            break;
          default:
            if (component.prefilledData) {
              newSub.push(component.prefilledData);
            } else {
              newSub.push("");
            }
            break;
        }
        newSubmission[component.id] = newSub;
      }
    })
    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable })
  }
  editTableRows(defaultData, section_id, defaultSubmission, dataType) {
    const { components, submission, choicelist } = this.state;
    const { companyTimezone } = this.props;
    var actualRows = this.checkForRows(components, section_id) + 1;
    var newSub = JSON.parse(JSON.stringify(submission));
    var newComponents = JSON.parse(JSON.stringify(components));
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    newComponents.map((component) => {
      if (component.type == 'section' && component.section_id == section_id) {
        component.dataType = dataType;
      }
    })
    components.filter(section => section.type != 'section' && section.section_id == section_id).map((component) => {
      var sub = newSub[component.id];

      if (Object.entries(defaultSubmission).length > 0) {
        if (defaultSubmission[component.id]) {
          defaultSubmission[component.id].map((defaultSub, defaultIndex) => {
            if (defaultSub.changed) {
              sub[defaultIndex] = defaultSub.value;
            }
          })
        }
      }

      if (actualRows > defaultData) {
        var dif = actualRows - defaultData;
        for (let i = 0; i <= (dif - 1); i++) {
          sub.splice(sub.length - 1, 1)
        }
      } else if (defaultData > actualRows) {
        var dif = defaultData - actualRows;
        for (let i = 0; i <= (dif - 1); i++) {

          switch (component.type) {
            case "datetime":
              sub.push(initialDateTime);
              break;
            case "dropdown":
              sub.push(choicelist[component.object_type_id][0]["id"]);
              break;
            case
              "signature",
              "timer",
              "timerauto",
              "document":
              sub.push(0);
              break;
            case "yesno",
              "yesnoall":
              sub.push(0);
              break;
            default:
              sub.push("");
              break;
          }
        }
      }
      newSub[component.id] = sub;
    })
    this.setState({ submission: newSub, components: newComponents, rerenderTable: !this.state.rerenderTable });
  }
  alertConfirm(msg, conf) {
      Swal.fire({
          text: msg,
          confirmButtonText: conf,
          confirmButtonColor: '#0B4DA0',
          backdrop: false
      });
  }
  resendRecipe(status){
    Swal.fire({
      title: "Attention: Recipe Form Editing",
      text: "This recipe already deducted ingredients. Changes won't affect inventory levels.", 
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      confirmButtonColor: colors.orange,
      backdrop: false
    }).then((result) => {
      if(result.isConfirmed)
        this.submitForm(status);
    })
  }
  // sortLocations(locations){
  //   var sortedLocations = [];
  //   locations.map((location, idx)=>{
  //     if(location?.site?.parentLocation == ""){
  //       //Site Level
  //       if(!location?.zone)sortedLocations.push(location);
  //       var zones = locations.filter((loc)=> loc?.zone?.parentLocation == location?.site?._id && !loc?.aisle);
  //       if(zones.length > 0){
  //         zones.map((zone)=>{
  //           //Zone Level
  //           sortedLocations.push(zone);
  //           var aisles = locations.filter((loc)=> loc?.aisle?.parentLocation == zone?.zone?._id && !loc?.rackBay);
  //           console.log(zone);
  //           console.log(aisles);
  //           if(aisles.length > 0){
  //             aisles.map((aisle)=>{
  //               //Aisle Level
  //               sortedLocations.push(aisle);
  //             });
  //           }
  //         })
  //       }
  //     }
  //   });

  //   return sortedLocations;
  // }
  getIngredientLotsByName(name, type, callback){
    var data = {
      name, 
      type
    }
    this.props.getIngredientLotsByName(data, (res)=>{
      if(res?.lots?.length){
        if(callback)callback(res.lots);
      }
    });
  }
  findLocation(location, locations){
    var foundLocation = locations.find((l)=>{
      var found = true;
                              
      if(location == "default_location"){
        if(!l?.site?.defaultLocation){
          found = false;
        }
      }
      if(location?.site?._id){
        if(l?.site?._id != location?.site?._id){
          found = false;
        }else{
          if(l?.zone?._id && !location?.zone?._id)found = false;
        }
      }
      if(location?.zone?._id){
        if(l?.zone?._id != location?.zone?._id){
          found = false;
        }else{
          if(l?.aisle?._id && !location?.aisle?._id)found = false;
        }
      }
      if(location?.aisle?._id){
        if(l?.aisle?._id != location?.aisle?._id){
          found = false;
        }else{
          if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
        }
      }
      if(location?.rackBay?._id){
        if(l?.rackBay?._id != location?.rackBay?._id){
          found = false;
        }else{
          if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
        }
      }
      if(location?.shelfLevel?._id){
        if(l?.shelfLevel?._id != location?.shelfLevel?._id){
          found = false
        }else{
          if(l?.bin?._id && !location?.bin?._id)found = false;
        }
      }
      if(location?.bin?._id){
        if(l?.bin?._id != location?.bin?._id){
          found = false
        }
      }
    
      return found;
    });

    return foundLocation;
  }
  filterLocations(location, locations){
    var foundLocation = locations.filter((l)=>{
      var found = true;
                              
      if(location == "default_location"){
        if(!l?.site?.defaultLocation){
          found = false;
        }
      }
      if(location?.site?._id){
        if(l?.site?._id != location?.site?._id){
          found = false;
        }else{
          if(l?.zone?._id && !location?.zone?._id)found = false;
        }
      }
      if(location?.zone?._id){
        if(l?.zone?._id != location?.zone?._id){
          found = false;
        }else{
          if(l?.aisle?._id && !location?.aisle?._id)found = false;
        }
      }
      if(location?.aisle?._id){
        if(l?.aisle?._id != location?.aisle?._id){
          found = false;
        }else{
          if(l?.rackBay?._id && !location?.rackBay?._id)found = false;
        }
      }
      if(location?.rackBay?._id){
        if(l?.rackBay?._id != location?.rackBay?._id){
          found = false;
        }else{
          if(l?.shelfLevel?._id && !location?.shelfLevel?._id)found = false;
        }
      }
      if(location?.shelfLevel?._id){
        if(l?.shelfLevel?._id != location?.shelfLevel?._id){
          found = false
        }else{
          if(l?.bin?._id && !location?.bin?._id)found = false;
        }
      }
      if(location?.bin?._id){
        if(l?.bin?._id != location?.bin?._id){
          found = false
        }
      }
    
      return found;
    });

    return foundLocation;
  }
  checkToLocationOnTransfer(components, submission){
    var valid = true;
    var sections = "";
    var section = components.find(component => component?.defaultLabel == "rawMaterials" && component.type == "section");
    if(section){
      var itemComponent = components.find(component => component?.defaultLabel == "itemName" && component?.type != "section" && component?.section_id == section?.section_id);
      var toLocationComponent = components.find(component => component?.defaultLabel == "toLocation" && component.type != "section" && component?.section_id == section?.section_id);
      if(itemComponent && toLocationComponent){
        var itemSubmission = submission[itemComponent.id];
        var toLocationSubmission = submission[toLocationComponent.id];
        if(typeof itemSubmission == "object" && itemSubmission?.length > 0){
          itemSubmission.map((sub, id) =>{
            if(sub){
              if(toLocationSubmission[id]){
                if(typeof toLocationSubmission[id] == "object" && toLocationSubmission[id]?.length > 0){
                  toLocationSubmission[id].map((location)=>{
                    if(!location){
                      valid = false;
                      sections += sections ? `, ${section.label}` : `${section.label}`;
                    }
                  })
                }else valid = false;
              }else valid = false;

            }
          });
        }
      }
    }
    var section = components.find(component => component?.defaultLabel == "finishedGoods" && component.type == "section");
    if(section){
      var itemComponent = components.find(component => component?.defaultLabel == "item" && component?.type != "section" && component?.section_id == section?.section_id);
      var toLocationComponent = components.find(component => component?.defaultLabel == "toLocation" && component.type != "section" && component?.section_id == section?.section_id);
      if(itemComponent && toLocationComponent){
        var itemSubmission = submission[itemComponent.id];
        var toLocationSubmission = submission[toLocationComponent.id];
        if(typeof itemSubmission == "object" && itemSubmission?.length > 0){
          itemSubmission.map((sub, id) =>{
            if(sub){
              if(toLocationSubmission[id]){
                if(typeof toLocationSubmission[id] == "object" && toLocationSubmission[id]?.length > 0){
                  toLocationSubmission[id].map((location)=>{
                    if(!location){
                      valid = false;
                      sections += sections ? `, ${section.label}` : `${section.label}`;
                    }
                  })
                }else valid = false;
              }else valid = false;

            }
          });
        }
      }
    }
    var section = components.find(component => component?.defaultLabel == "packagingMaterials" && component.type == "section");
    if(section){
      var itemComponent = components.find(component => component?.defaultLabel == "item" && component?.type != "section" && component?.section_id == section?.section_id);
      var toLocationComponent = components.find(component => component?.defaultLabel == "toLocation" && component.type != "section" && component?.section_id == section?.section_id);
      if(itemComponent && toLocationComponent){
        var itemSubmission = submission[itemComponent.id];
        var toLocationSubmission = submission[toLocationComponent.id];
        if(typeof itemSubmission == "object" && itemSubmission?.length > 0){
          itemSubmission.map((sub, id) =>{
            if(sub){
              if(toLocationSubmission[id]){
                if(typeof toLocationSubmission[id] == "object" && toLocationSubmission[id]?.length > 0){
                  toLocationSubmission[id].map((location)=>{
                    if(!location){
                      valid = false;
                      sections += sections ? `, ${section.label}` : `${section.label}`;
                    }
                  })
                }else valid = false;
              }else valid = false;

            }
          });
        }
      }
    }
    var section = components.find(component => component?.defaultLabel == "workInProgress" && component.type == "section");
    if(section){
      var itemComponent = components.find(component => component?.defaultLabel == "item" && component?.type != "section" && component?.section_id == section?.section_id);
      var toLocationComponent = components.find(component => component?.defaultLabel == "toLocation" && component.type != "section" && component?.section_id == section?.section_id);
      if(itemComponent && toLocationComponent){
        var itemSubmission = submission[itemComponent.id];
        var toLocationSubmission = submission[toLocationComponent.id];
        if(typeof itemSubmission == "object" && itemSubmission?.length > 0){
          itemSubmission.map((sub, id) =>{
            if(sub){
              if(toLocationSubmission[id]){
                if(typeof toLocationSubmission[id] == "object" && toLocationSubmission[id]?.length > 0){
                  toLocationSubmission[id].map((location)=>{
                    if(!location){
                      valid = false;
                      sections += sections ? `, ${section.label}` : `${section.label}`;
                    }
                  })
                }else valid = false;
              }else valid = false;

            }
          });
        }
      }
    }
    if(!valid){
      this.alertConfirm("Please complete to location fields in " + sections + ".", "Ok");
    }
    return valid;
  }
  openDropDown(position){
    const { dropDownOpen, rerenderTable } = this.state;
    /* if(dropDownOpen == position){
      this.setState({ dropDownOpen: -1, rerenderTable: !rerenderTable });
    }else{ */
      this.setState({ dropDownOpen: position, rerenderTable: !rerenderTable });
    /*}  */
  }
  isIdInSection(section_id){
    const { dropDownOpen} = this.state;
    var components = this.state.components.filter((component) => component.section_id == section_id && component.type != "section");
    var c = components.find((component)=>{ 
      return component.id == dropDownOpen 
    });

    return c;
  }
  onTimeComponentChange(id, value, position){
    if(position || position == 0){
      if(this.timeSubmissions[id]){
        this.timeSubmissions[id][position] = value;
      }else{
        this.timeSubmissions[id] = [];
        this.timeSubmissions[id][position] = value;
      }
    }else{
      this.timeSubmissions[id] = value;
    }
  }
  updateTimeSubmissions(callback){
    var newSubmission = JSON.parse(JSON.stringify(this.state.submission));
    Object.keys(this.timeSubmissions).map((key, idx)=>{
      newSubmission[key] = this.timeSubmissions[key];
    });
    this.setState({ submission: newSubmission }, ()=>{
      if(callback)callback();
    })
  }
  render() {
    const { submission, choicelist, checkboxkey, showPaymentInfo, callOnSave, components, lotConsecutive, formLoaded, rerenderTable, palletComponentChanged, itemComponentChanged, resetInventorySearch, inventoryLocations, dropDownOpen } = this.state;
    const { submissionSelected, formSubmission, isLoadingIssue, companyTimezone, promptMessages, inventoryTypes, isOrder, formId, isRecipe, updatedWizard, isInvoice, isShipmentLog, isReceivingLog, isInventoryTransfer, newReport } = this.props;
    const status = submissionSelected ? submissionSelected.status : "new";
    let issues = submissionSelected ? submissionSelected.issues : null;
    if (this.state.tmpIssues.length > 0)
      issues = this.state.tmpIssues;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    return (
      <View style={{ flexDirection: "row", }}>
        <PaymentInfo visible={showPaymentInfo} onSave={this.submitFormSave.bind(this)} callOnSave={callOnSave} />
        <View style={{ flex: 2, }} />
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
          {
            this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (

              <ScrollView
                contentContainerStyle={styles.innerView}
              >
                {
                  status != "new" ?
                    <View>
                      <Text style={styles.reportId}>Report Id: {submissionId}</Text>
                    </View>
                    : null
                }


                {components.filter(section => (section.type == 'section' && !section.hide)).map((section, id) => (

                  section.direction && section.direction == 'table' ?
                    <Section
                      showSeparator={id === 0}
                      isLast={id == components.filter(section => section.type == 'section').length - 1}
                      label={section.label}
                      onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                      id={section.id}
                      media={section.attachment}
                      formSubmission={formSubmission}
                      attachment_type={section.attachment_type}
                      mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                      formId={this.props.formId}
                      setBigFileIsLoading={this.props.setBigFileIsLoading}
                      direction={section.direction}
                      defaultData={section?.defaultData ? section?.defaultData : 1}
                      section={section}
                      components={components}
                      choicelist={choicelist}
                      checkboxkey={checkboxkey}
                      onSaveDefault={(defaultData, defaultSubmission, dataType) => {
                        this.editTableRows(defaultData, section?.section_id, defaultSubmission, dataType);
                      }}
                      defaultSubmission={section?.defaultSubmission ? section?.defaultSubmission : {}}
                      rerenderTable={rerenderTable}
                      status={status}
                      palletComponentChanged={palletComponentChanged}
                      itemComponentChanged={itemComponentChanged}
                    >
                      <View style={{position: 'relative', zIndex: 1}}> 
                        <View style={{ marginTop: 10, overflow: 'auto', zIndex: 1}}>
                          {/* <View style={dropDownOpen != -1 ? { marginTop: 10} : { marginTop: 10, overflowY: 'hidden' }}> */}
                            {/* <Table style={{ justifyContent: "flex-start" }} borderStyle={{  }}>
                              <Row data={this.getTableHeaders(components, section.section_id)} widthArr={this.getCellArrayWidth(components, section.section_id)} style={styles.head} textStyle={{ ...styles.text, color: 'white' }} />
                              {
                                this.renderRows(this.checkForRows(components, section.section_id), components, section.section_id)
                              }
                            </Table> */}
                            {/* New table structure */}
                            <View style={{ display: "flex", flexDirection: "column", borderColor: '#fff', height: 'auto', zIndex: 2 }}>
                              <View style={{display: "flex", flexDirection: "row" }}> 
                                {
                                  this.getTableHeaders(components, section.section_id).map((header, idx)=>{
                                    var width = this.getCellArrayWidth(components, section.section_id);
                                    return <View style={{ width: width[idx], color: 'white',...styles.text, ...styles.head}}>
                                      {header}
                                    </View>
                                  })
                                }
                              </View>
                                {
                                  this.renderRows(this.checkForRows(components, section.section_id), components, section.section_id)
                                }
                            </View>
                        </View>
                        <NewIssue
                          visible={this.state.openTableIssue}
                          id={this.state.compIssueId}
                          label={this.state.issueLabel}
                          onSubmitIssue={(issue) => {
                            this.props.loadingIssue(this.state.compIssueId);
                            this.setState({ rerenderTable: !this.state.rerenderTable });
                            this.saveIssue({ ...issue, position: this.state.issuePosition }, issues, submissionId, () => {
                              setTimeout(() => {
                                this.props.loadingIssue(null);
                                this.setState({ rerenderTable: !this.state.rerenderTable });
                              }, 1000);
                            });
                          }}
                          handleClose={() => {
                            this.setState({
                              openTableIssue: false,
                              compIssueId: null,
                              issueLabel: '',
                              issuePosition: null,
                              issueWidth: "small",
                              rerenderTable: !rerenderTable
                            })
                          }}
                          width={this.state.issueWidth}
                        />
                      </View> 
                      {(status != 'archived' && status != 'approved' 
                        // && section?.defaultLabel != "moreRecipeInformation"
                      ) ?
                        <View style={styles.buttonsContainer}>
                          <TouchableOpacity style={styles.btnAdd}
                            onPress={() => { this.addTableRow(section.section_id, choicelist) }}
                          >
                            <Text>
                              <Icon name="plus-circle-outline" style={{ fontSize: 30, color: colors.add }} />
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity style={styles.btnAdd}
                            onPress={() => {
                              var rows = this.checkForRows(components, section.section_id);
                              this.restTableRow(section.section_id, rows ? false : true);
                            }}
                          >
                            <Text>
                              <Icon name="close-circle-outline" style={{ fontSize: 30, color: colors.quit }} />
                            </Text>
                          </TouchableOpacity>
                        </View> : null
                      }
                    </Section>
                    :

                    <Section
                      showSeparator={id === 0}
                      isLast={id == components.filter(section => section.type == 'section').length - 1}
                      label={section.label}
                      onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                      id={section.id}
                      media={section.attachment}
                      formSubmission={formSubmission}
                      attachment_type={section.attachment_type}
                      mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                      formId={this.props.formId}
                      choicelist={choicelist}
                      setBigFileIsLoading={this.props.setBigFileIsLoading}
                      checkboxkey={checkboxkey}
                      defaultSubmission={{}}
                      rerenderTable={rerenderTable}
                      status={status}
                      palletComponentChanged={palletComponentChanged}
                      itemComponentChanged={itemComponentChanged}
                      sectionIndex={this.isIdInSection(section.section_id) ? 1 : 0}
                    >
                      {components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
                        let new_label;
                        var disabled = false;
                        switch (component.type) {
                          case "customlotcode":
                            new_label = `${component.label} - ${component.lotcodetypename}`;
                            break;
                          case "inventory":
                            new_label = `${component.label} - ${component.inventorytypename} Code`;
                            if (typeof submission[component.id] == "undefined")
                              submission[component.id] = { lotcode: "", productname: "", quantity: "" };
                            break;
                          default:
                            new_label = `${component.label}`;
                        }
                        if (component?.dataType) {
                          switch (component?.dataType) {
                            case "default_data":
                              if(component?.defaultLabel != "date&time")
                                disabled = true;
                              break;
                            default:
                              disabled = false;
                              break;
                          }
                        }

                        return <>
                          {
                            isLoadingIssue != null && component.id == isLoadingIssue ?
                              <ActivityIndicator size={150} color="rgb(0, 47, 108)"></ActivityIndicator>
                              :
                              <View style={component.type == "dropdown" ? { position: 'relative', zIndex: dropDownOpen == component.id ? 2 : 1 } : { position: 'none'}}>
                                {
                                  !component?.hide &&
                                    <FormComponent
                                      id={component.id}
                                      type={component.type}
                                      key={component.id}
                                      label={component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? new_label + " *" : new_label}
                                      placeholder={component.placeholder ? component.placeholder : ""}
                                      componentLink={component.componentLink ? component.componentLink : ""}
                                      enabledLink={component.enabledLink ? component.enabledLink : ""}
                                      initial={submission[component.id]}
                                      onChange={(id, value, subtype, prepopulate) => {
                                          if(component.type == "time" || component.type == "timer"){
                                            this.onTimeComponentChange(id, value, null);
                                          }else{
                                            this.onComponentChange(id, value, subtype, null, prepopulate)
                                          }
                                      }}
                                      options={choicelist[component.object_type_id]}
                                      checkboxkey={checkboxkey}
                                      status={submissionSelected ? submissionSelected.status : false}
                                      submissionSelected={submissionSelected ? submissionSelected : null}
                                      showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                                      lotConsecutive={lotConsecutive}
                                      customlot={false}
                                      isIssuable={submissionId != null}
                                      onSubmitIssue={(issue) => {
                                        this.props.loadingIssue(component.id);
                                        this.setState({ rerenderTable: !this.state.rerenderTable });
                                        this.saveIssue(issue, issues, submissionId, () => {
                                          setTimeout(() => {
                                            this.props.loadingIssue(null);
                                            this.setState({ rerenderTable: !this.state.rerenderTable });
                                          }, 1000);
                                        });
                                      }}
                                      companyTimezone={companyTimezone}
                                      defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : disabled}
                                      promptMessages={promptMessages}
                                      showInventorySearch={component?.showInventorySearch || (isRecipe && component?.defaultLabel == "lotcode" && (section?.defaultLabel == "ingredients" || section?.defaultLabel == "packagingMaterials" ))}
                                      showFinishedGoodsSearch={component?.showFinishedGoodsSearch}
                                      showWorkInProgressSearch={component?.showWorkInProgressSearch}
                                      showItemsSearch={component?.showItemsSearch}
                                      showPackagingSearch={component?.showPackagingSearch}
                                      defaultLabel={component?.defaultLabel}
                                      isRecipe={isRecipe}
                                      isInvoice={isInvoice}
                                      submission={submission}
                                      isShipmentLog={isShipmentLog}
                                      resetInventorySearch={resetInventorySearch}
                                      components={components}
                                      inventoryTypes={inventoryTypes}
                                      searchClients={component?.searchClients}
                                      searchSuppliers={isReceivingLog && component?.defaultLabel == "approvedSupplier"}
                                      section_id={section?.section_id}
                                      isInventoryTransfer={isInventoryTransfer}
                                      inventoryLocations={inventoryLocations}
                                      newReport={newReport}
                                      openDropDown={()=>{ this.openDropDown(component.id)}}
                                      backgroundColor={null}
                                      isLast={false}
                                    />
                                }
                                {issues && this.renderIssue(issues, component) != null ? <View style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 10,
                                  marginBottom: 10,

                                }}>
                                  {this.printAllIssues(issues, component, null, false)}
                                </View> : null}

                              </View>
                          }

                        </>



                      })}
                    </Section>


                ))}
                {
                  formSubmission ?
                    null : 
                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }} 
                      formLoaded={formLoaded}
                      submitForm={(sts) => sts ? (isRecipe && status == "edit_required") ? this.updateTimeSubmissions(()=>{this.resendRecipe(sts)})  : this.updateTimeSubmissions(()=>{this.submitForm(sts)}) :  this.updateTimeSubmissions(()=>{this.submitForm()})}
                      submitFormSave={(status) => status ? this.updateTimeSubmissions(()=>{this.submitFormSave(status)}) : this.updateTimeSubmissions(()=>{this.submitFormSave()})} record={this.props.submissionSelected} navigateTo={(page) => this.props.navigation.navigate(page)} />
                }
                {
                  formSubmission ?
                    null : 
                    this.props.isSaving? null : this.showSave()
                }
                {
                  formSubmission ?
                    <TouchableOpacity style={styles.readOnlyContainer}>
                      <Text style={{ alignSelf: "center" }}>This record is read only, the form was deleted from the admin and this record can not be modified.</Text>
                    </TouchableOpacity> : null
                }



              </ScrollView>
            ) : this.props.isLoading ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            ) : this.props.isSaving && !this.state.autosave ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            ) : this.props.isLoadingBigFile ?
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Uploading...</Text>
              </View>
              : (
                <View style={styles.fixedContainer}>
                  <View style={styles.fixedContainerHT}>
                    <MaterialCommunityIcons
                      name="alert-circle"
                      size={32}
                      color="#d00"
                      style={styles.icon}
                    />
                    <Text style={styles.errorText}>{this.props.error}</Text>
                  </View>
                  <View style={styles.fixedContainerHB}>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.retry(
                          this.props.isErrorSave,
                          this.props.components,
                          this.props.choicelist,
                          this.state.submission,
                          this.props.newReport ? uuid.v1() : this.state.uuid,
                          this.props.task_id
                        );
                      }}
                    >
                      <Text>Retry</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}

          <LogDialog />
        </View>
        <View style={{ flex: 2 }} />
      </View>
    );


  }
}

export default connect(
  (state) => {
    return {
      company_id: state.user.get("company_id"),
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      isLoadingBigFile: state.forms.getIn(["form", "status"]) === "loading_big_file",
      isErrorSave: state.forms.getIn(["form", "status"]) === "errorSaving",
      isErrorRetrieve:
        state.forms.getIn(["form", "status"]) === "errorRetrieving",
      formId: state.forms.getIn(['form', 'id']),
      promptMessages: state.forms.getIn(['form', 'promptMessages']),
      isLoaded: state.forms.getIn(["form", "status"]) === "loaded",
      isSaving: state.forms.getIn(["form", "status"]) === "saving",
      error: state.forms.getIn(["form", "errorMessage"]),
      components: state.forms.getIn(["form", "components"]),
      choicelist: state.forms.getIn(["form", "choicelist"]),
      formSubmission: state.forms.getIn(["form", "formSubmission"]),
      previousSubmissions: state.submissions.get("previousSubmissions"),
      /*syncState: state.sync.get("state"),*/
      isLogGroup: state.loggroups.get("isLogGroup"),
      logGroupSelected: state.loggroups.get("logGroupSelected"),
      submissionSelected: state.submissions.get("submissionSelected"),
      isSubmission: state.submissions.get("isSubmission"),
      isLoadingIssue: state.submissions.get("loadingIssue"),
      payment_completed: state.user.get("payment_completed"),
      companyTimezone: state.user.get("companyTimezone"),
      userRole: state.user.get("userRole"),
      taskSelected: state.tasks.get("taskSelected"),
      inventoryTypes: state.forms.get("pallets"),
      items: state.forms.get("items"),
      packagingMaterials: state.forms.get("packagingMaterials"),
      updatedWizard: state.forms.get("updatedWizard"),
    };
  },
  (dispatch, props) => {

    return {
      sendSubmit: (submissionId, submissions, comment, callback) => dispatch(sendSubmit(submissionId, submissions, comment, callback)),
      checkRecordStatus: (uuid, callback) => dispatch(checkRecordStatus(uuid, callback)),
      showStatusError: (status) => dispatch(showStatusError(status)),
      checkLogStatus: (task_id, callback) => dispatch(checkLogStatus(task_id, callback)),
      showLogStatusError: (status) => dispatch(showLogStatusError(status)),
      loadingIssue: (value) => dispatch(loadingIssue(value)),
      getMyInventoryTypes: (data, callback) => dispatch(getMyInventoryTypes(data, callback)),
      getMyItems: (data, callback) => dispatch(getMyItems(data, callback)),
      getMyPackaging: (data, callback) => dispatch(getMyPackaging(data, callback)),
      setBigFileIsLoading: (payload) => dispatch(setBigFileIsLoading(payload)),
      getForm: (idSubmission, callback) => {
        dispatch(getForm(props.formId, idSubmission, callback));
      },
      quitOrderForm: (id) => {
        dispatch(quitOrderForm(id));
      },
      editDefaultData: (data, callback) => dispatch(editDefaultData(data, callback)),
      setStatusChange: () => { dispatch(setStatusChange()) },
      unsetStatusChange: () => { dispatch(unsetStatusChange()) },
      setLogGroupsSubAction: () => {
        dispatch(setLogGroupsSubAction());
      },
      getLocations: (data, callbak) => dispatch(getLocations(data, callbak)),
      approved: () => dispatch(approved()),
      getIngredientLotsByName: (data, callback) => {
        dispatch(getIngredientLotsByName(data, callback));
      },
      retry: (
        isErrorSave,
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        status
      ) => {
        if (isErrorSave) {
          dispatch(
            beginSubmit(
              props.formId,
              props.title,
              components,
              choicelist,
              submission,
              uuid,
              task_id,
              false,
              status
            )
          );
        } else {
          dispatch(getForm(props.formId, props.submissionSelected?._id));
        }
      },
      startSubmit: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        logroup,
        status,
        isRecipe,
        navigate,
        retry,
        sendAnyway,
        isInventoryTransfer
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            false,
            logroup,
            status,
            isRecipe,
            navigate,
            retry,
            sendAnyway,
            isInventoryTransfer
          )
        );
      },
      autoSave: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        autosave
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            autosave
          )
        );
      },
    };
  }
)(Form);

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  issueContainer: {
    flex: 1,
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: 'pink',
  },
  readOnlyContainer: {
    flexGrow: 1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: "#B3B7BC",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoid: {
    flex: 1,
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  fixedContainer: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
  },
  fixedContainerHB: {
    marginTop: 20,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  innerView: {
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  menuContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,
  },
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.save,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  buttonText: {
    color: colors.label_blue,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  iconInProgress: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconEditRequired: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForReview: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForApproval: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightblue',
  },
  iconApproved: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgreen',
  },
  iconRejected: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'pink',
  },
  issueBox: {
    alignItems: "center",
    backgroundColor: 'pink',
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    marginBottom: 10,
    height: 60,
  },
  issueText: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#444",
  },
  readOnlyTxt: {
    fontSize: 22,
    borderRadius: 4,
    backgroundColor: 'pink',
    margin: 10,
    padding: 10,
    fontFamily: "Roboto",
  },
  viewContainerMax: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  head: {
    backgroundColor: colors.orange,
    color: 'white',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  text: {
    padding: 3
  },
  btnAdd: {
    width: 40
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  reportId: {
    paddingLeft: 10,
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary
  }

});