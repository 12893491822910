import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import {Alert} from 'react-native';
import { 
     RECORD_GET_REQUEST,
     RECORD_INPROGRESS_RESPONSE,
     RECORD_EDITREQUIRED_RESPONSE,
     RECORD_READYFORREVIEW_RESPONSE,
     RECORD_READYFORAPPROVAL_RESPONSE,
     SET_RECORD_STATUS_LOADING,
     SET_RECORDS_LOADING,
     ADD_DATA_TO_LOGRECORDS,
     SET_STATUS_CHANGE,
     UNSET_STATUS_CHANGE,
     SET_LOG_FILTER,
     SET_APPROVED_SUBMIT
} from '../constants/actions';

const initialState = {
    records: [],
    logRecords:[],
    statusLoading: false,
    recordsLoading: false,
    lastFormUpdated: null,
    preventStatusChange: false,
    LogFilter: null,
    approved: false
};

export default handleActions({
  [ADD_DATA_TO_LOGRECORDS] : (state, action) =>{
    return state.set('logRecords', action.payload);
  },
  [SET_RECORD_STATUS_LOADING]: (state,action) => {
    return state.set('statusLoading',action.payload);
  },
  [SET_RECORDS_LOADING]: (state,action) => {
    return state.set('recordsLoading',true);
  },
  [RECORD_GET_REQUEST]: (state, action) => {
    const dateNow = new Date();
    return state.set('records', action.payload)
                .set("lastFormUpdated",dateNow)
                .set('recordsLoading',false);    
  },
  [RECORD_INPROGRESS_RESPONSE]: (state, action) => {
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);  
    }else return state.set('statusLoading',false);  
  },
  [RECORD_EDITREQUIRED_RESPONSE]: (state, action) => {    
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);    
    }else return state.set('statusLoading',false);
  },
  [RECORD_READYFORREVIEW_RESPONSE]: (state, action) => {   
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);    
    }else return state.set('statusLoading',false);
  },
  [RECORD_READYFORAPPROVAL_RESPONSE]: (state, action) => {
    const {records,record,result} = action.payload;
    if(result == 'success'){
      return state.set('records', records).set('statusLoading',false);
    }else return state.set('statusLoading',false);
  
    
  },
  [SET_STATUS_CHANGE]: (state, action) => {
    console.log("is in SET_STATUS_CHANGE "+action.payload)
    return state.set('preventStatusChange',action.payload);
  },
  [UNSET_STATUS_CHANGE]: (state, action) => {
    console.log("is in UNSET_STATUS_CHANGE "+action.payload)
    return state.set('preventStatusChange',action.payload);
  },
  [SET_LOG_FILTER]: (state, action) => {
    return state.set('LogFilter',action.payload);
  },[SET_APPROVED_SUBMIT]: (state, action) => {
    var approved = state.get("approved");
    return state.set("approved", !approved);
  },
}, Immutable.fromJS(initialState));